import _ from "lodash";
import DebugLogger from "../../utils/DebugLogger";
import * as Helper from "../../utils/Helper";
import MessageBox from "../ui/MessageBox";
import BaseView from "./BaseView";

class BaseComponent extends BaseView {

    get reducer() {
        throw new Error("Not implemented.");
    }

    get actionHandlers() {
        return {};
    }

    constructor(props) {
        super(props);

        DebugLogger.log("Lifecycle: " + this.viewName + " is created.");

        if (this.reducer) {
            DebugLogger.log("Lifecycle: register reducer for " + this.viewName);

            this.reducer.register();
        }
    }

    partnerRole = {
        ManagerPartner: 0,
        EditorPartner: 1,
        AssistantPartner: 2,
    }

    get isAdmin() {
        return Helper.isAdmin(this.props.user);
    }

    get isOnCall() {
        return Helper.isOnCall(this.props.user);
    }

    get isTeacher() {
        return Helper.isTeacher(this.props.user);
    }

    get isAgent() {
        return Helper.isAgent(this.props.user);
    }

    get isStudent() {
        return Helper.isStudent(this.props.user);
    }

    get isBookManager() {
        return Helper.isBookManager(this.props.user);
    }

    get isPartner() {
        return Helper.isPartner(this.props.partner , this.partnerRole);
    }

    get isEditorPartner() {
        return Helper.isEditorPartner(this.props.partner, this.partnerRole);
    }

    get isManagerPartner() {
        return Helper.isManagerPartner(this.props.partner , this.partnerRole);
    }

    get isAssistantPartner() {
        return Helper.isAssistantPartner(this.props.partner , this.partnerRole);
    }

    get enableProduct() {
        return this.props.partner?.enableProduct;
    }

    get enableOtherAnswer() {
        return this.props.partner?.enableOtherAnswer;
    }

    get enablePromotion() {
        return this.props.partner?.enablePromotion;
    }

    get enableBss() {
        return this.props.partner?.enableBbs;
    }

    componentDidMount() {
        super.componentDidMount();

        DebugLogger.log("Lifecycle: " + this.viewName + " is mounted.");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.localState && this.props.localState) {
            var preAction = prevProps.localState.action;
            var curAction = this.props.localState.action;
            if (preAction && curAction) {
                if (curAction.isDone) {
                    if (!preAction.isDone) {
                        if (this.componentHandleActionDone) this.componentHandleActionDone(curAction, this.props);
                    }
                } else if (curAction.error) {
                    if (!preAction.error) {
                        if (this.componentHandleActionError) this.componentHandleActionError(curAction, this.props);
                        else this.displayError(curAction.error); // by default, we display error in a message box
                    }
                }
            }
        }

        // we only process the error one time
        // handle general errors for now
        // if there are no general errors, but only action errors,
        // we will not need this part of logic any more
        if (!prevProps.localState.error && this.props.localState.error) {
            if (this.componentHandleError) this.componentHandleError(this.props.localState.error, this.props);
            else this.displayError(this.props.localState.error); // by default, we display error in a message box
        }
    }

    componentWillUnmount() {
        if (this.reducer) {
            DebugLogger.log("Lifecycle: unregister reducer for " + this.viewName);

            this.reducer.unregister();
        }

        DebugLogger.log("Lifecycle: " + this.viewName + " is destroyed.");
    }

    componentHandleActionDone(action, props) {
        if (!_.isEmpty(action.type)) {
            if (this.actionHandlers[action.type]) {
                if (this.actionHandlers[action.type].done) {
                    this.actionHandlers[action.type].done(action, props);
                }
            }
        }
    }

    componentHandleActionError(action, props) {
        if (!_.isEmpty(action.type)) {
            if (this.actionHandlers[action.type]) {
                if (this.actionHandlers[action.type].error) {
                    this.actionHandlers[action.type].error(action, props);
                }
            }
        }
    }

    getExecutedAction(actionType) {
        if (this.props && this.props.localState && this.props.localState.executedActions) {
            return this.props.localState.executedActions[actionType];
        }

        return null;
    }

    isActionExecuting(actionType) {
        if (this.props && this.props.localState && this.props.localState.executedActions) {
            var action = this.props.localState.executedActions[actionType];
            return action ? action.isExecuting : false;
        }

        return false;
    }

    isActionDone(actionType) {
        if (this.props && this.props.localState && this.props.localState.executedActions) {
            var action = this.props.localState.executedActions[actionType];
            return action ? action.isDone : false;
        }

        return false;
    }

    displayError(error) {
        if (!error) {
            return; // do nothing
        }

        var newError = {...error};
        if (error.code || error.code === 0) {
            newError.message = error.message + " (code: " + error.code + ")";
        }

        MessageBox.danger({
            text: newError.message,
            duration: 5000
        });
    }

    displaySuccess(info) {
        if (!info) {
            return; // do nothing
        }

        MessageBox.success({
            text: info.message || info,
            duration: 5000
        });
    }
}

export default BaseComponent;
