import _ from "lodash";

const mobileVersionSuffix = 3;    //web mobile
const versionSuffix = 4; //web

const InternalAppConfig = {
    APP_SERVICE_BASE: window.g_settings.serviceBaseUrl, //"https://api.yidu.ink"
    FILE_SERVER: window.g_settings.localFileServerUrl,
    APP_CLIENT_ID: "sampleconsumer",
    APP_CLIENT_SECRET: "asdf@3iouae234(*213",
    APP_CLIENT_VERSION: "2.2.0", // client version
    APP_CLIENT_BUILD: "874" + versionSuffix, // client build
    MOBILE_APP_CLIENT_BUILD: "874" + mobileVersionSuffix, // client build
    TWITTER_CONSUMER_KEY: "eujSyWFaL7qbRzOrSvNag",
    FACEBOOK_CLIENT_ID: "669631993734738", //NOTE:: for facebook client id please change \AiChinese.Mobile.App\AiChinese\android\app\src\main\res\values\strings.xml
    SESSION_STORAGE_KEY: "SESSION_STORAGE_KEY",
    INITIAL_URL: "", // if app is start by special scheme url(iml://app), we store it here to prevent handle it multiple times
    APPLE_APP_ID: "1259009536",
    GOOGLE_APP_PACKAGE_NAME: "com.chinesehomework.android.app",
    APP_NAME: "YiDu",
    IOS_APP_ID: "com.chinesehomework.ios.app",
    ANDROID_APP_ID: "com.chinesehomework.android.app",
    IS_PREMIUM_APP: false,
    PREMIUM_APP_SCHOOL_ID: "",
    APP_ADS_REFRESH_INTERVAL: 20,
    PREMIUM_APP_ID: "",
    IMAGII_DISABLED: false,
    IS_APP_UNDER_REVIEW: false, // indicate if current app is under review
    GOOGLE_WEB_CLIENT_ID: "711266234345-use4rleb2i8tou4ju0njfhiqk3l4i024.apps.googleusercontent.com",
    GOOGLE_IOS_CLIENT_ID: "711266234345-ak7sag17c4fa9rb2rhqd1gnls5pm50qq.apps.googleusercontent.com",
    WECHAT_APP_ID: "wx43715d0c74d796cd",
    SHOW_UNITTEST: true,
    STORAGE_KEY: "STORAGE_KEY",
    LANGUAGE_SETTING: "LANGUAGE_SETTING",
    ONESIGNAL_APP_ID: "13f154e2-f34b-4825-a509-1ccf97c6bbb3",
    WECHAT_UNIVERSAL_LINK: "https://www.yidu.ink/app/",
    ANDROID_APP_DOWNLOAD_LINK: "http://www.yidu.ink/download/app/yidu.apk",
    IOS_APP_DOWNLOAD_LINK: "https://itunes.apple.com/us/app/yidu/id1540889657?mt=8",
    UNIT_PASSING_SCORE: 0.7,
    AUDIO_RATE_OF_ROBOT: 0.5,
    AUDIO_RATE_OF_USER: 0.5,
    MAX_AUDIO_RECORD_LIMIT: 55,
    ENABLE_OFFLINE: false,
    LATEST_APPS: null,
    LOCAL_REGION_SERVERS: window.g_settings.localRegionServers,
};

class AppConfig {
    constructor() {
        _.assignIn(this, InternalAppConfig);
    }

    updateAppConfig = newConfig => {
        _.assignIn(this, newConfig);
    };
}

const appConfig = new AppConfig();

export default appConfig;
