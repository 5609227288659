export default {
    FILE_STORAGE: {
        ROOT_PATH: "~/fs/",
        TEMP_PATH: "~/fs/temp/",
        PINYIN_PATH: "~/fs/dictionary/pinyin/",
    },
    LOCAL_FILE_STORAGE: {
        ROOT_PATH: "/fs/",
        TEMP_PATH: "/fs/temp/",
        TALK_CHINESE_ROOT_PATH: "/fs/users/talkchinese/", // TODO from wei: need to remove this one
    },
    TEXT_MARK_TYPE: {
        PLAIN_TEXT: 1,
        HIGHLIGHT: 2,
        INPUT: 4,
        UNDERLINE: 8,
        BOLD: 16,
        POP_INPUT: 32, // a text that you can click it, a bottom sheet with a input will show and you can set the row of input, after input the content will be your input
        ICON: 64,
        SKIP: 128,
        KEYWORD: 256,
        TYPO: 512,
        MISSPELT: 1024,
        NUMBER: 2048,
        ERRORLETTER: 4096,
        WRITE_INPUT: 8192, // handwriting input
    },
    SENTENCE_MARK_TYPE: {
        PLAIN_TEXT: 1,
        ALIGN_CENTER: 2,
        ALIGN_RIGHT: 4,
        LEFT_INDENT: 8, //indent the paragraph at left
        DIALOG: 16,
    },
    FIELD_TYPE: {
        CHARSFIELD: 0,
        IMAGEFIELD: 1,
        AUDIOFIELD: 2,
    },
    TABLE_COLUMN_TYPE: {
        UNKNOWN: 0,
        TEXT: 1,
        IMAGE: 2,
        TRANSLATION: 3,
    },
    FIELD_TYPE_MAP_ARRAY: ["CharsField", "ImageField", "AudioField"],
    VALID_MP3_MIMES: [
        "audio/mpeg",
        "audio/x-mpeg",
        "audio/mp3",
        "audio/x-mp3",
        "audio/mpeg3",
        "audio/x-mpeg3",
        "audio/x-mpeg-3",
        "audio/mpg",
        "audio/x-mpg",
        "audio/x-mpegaudio",
        "video/mpeg",
        "video/x-mpeg",
    ],
    SUPER_ADMIN_ID: "10001",
    CHARS_FIELD_SYLLABLES_SEPARATOR: "%#%",
    USER_GUEST: 'Guest',
    SUPPORTED_LANGUAGES: [
        {code: "zh", name: "中文", culture: "zh-CN"},
        {code: "en", name: "English", culture: "en-US"},
        {code: "th", name: "ภาษาไทย", culture: "th-TH"},
        {code: "ko", name: "한국인", culture: "ko-KR"},
        {code: "ja", name: "日語", culture: "ja-JP"},
        {code: "pt", name: "Português", culture: "pt"},
        {code: "fr", name: "Français", culture: "fr"},
        {code: "ru", name: "Русский язык", culture: "ru"},
    ],
    USER_ROLE:{
        USER: 0,
        ADMIN: 1,
        TEACHER: 2,
        AGENT: 4,
        BOOK_MANAGER: 8,
        ON_CALL: 16,
    },
    USER_TABLE_TYPES:{
        STUDENT_USER: 0,
        TEACHER_USER: 1,
        AGENT_USER: 2,
        OTHER_USER: 3,
    },
    BACKGROUND_TASK_TYPE: {
        API_CALL: "API_CALL",
        UNIT_API_CALL: "UNIT_API_CALL",
        UPDATE_UNIT_RESULT: "UPDATE_UNIT_RESULT",
        UNIT_UPLOAD: "UNIT_UPLOAD",
        RESTART_UNIT: "RESTART_UNIT",
        RECOGNIZE_AUDIO: "RECOGNIZE_AUDIO",
        COMPLETE_HOMEWORK: "COMPLETE_HOMEWORK",
        START_HOMEWORK: "START_HOMEWORK",
    },
    BACKGROUND_TASK_QUEUE_TYPE: {
        NORMAL: "NORMAL TASK",
        UPLOAD: "UPLOAD TASK",
    },
    PRIMARY_COLOR: "#19589b",
    SECONDARY_COLOR: "#eea236",
     CODES: {
        SPA_CLIENT_OLD: -100,
        TO_BE_AUTHENTICATED: -1,
        ACCESS_VIOLATION: -2,
        MISSING_EMAIL: -3,
        SSO_VIOLATED: 108,
        NOT_EXIST_CURUSER: -8,
        ACCOUNT_NOT_EXIST: 100,
        INACTIVE_ACCOUNT: 101,
        EXIST_ACCOUNT: 103,
        MUST_IN_WAITLIST_DUE_TO_MIN_PLAYER_BOUND: 119,
        MUST_IN_WAITLIST_DUE_TO_MIN_RESTRICT_TEAM_JOIN: 120,
        USER_HOMEWORK_UNIT_NOT_EXIST: 123,
        USER_HOMEWORK_NOT_EXIST: 124,
        ALREADY_JOINED_TEAM: 125,
        TEAM_JOIN_PENDING: 126,
        INVITED_MEMBER_JOIN: 127,
        ADDED_MEMBER_BY_ADMIN: 128,
        USER_HOMEWORK_NOT_COMPLETED: 130,
        USER_HOMEWORK_ALREADY_COMPLETED: 131,
        NEED_PASS_QUIZ: 138,
        INVALID_NETWORK_EMAIL: 140,
        REQUIRE_PAYMENT: 141,
        REQUIRE_SIGN_WAIVERS: 142,
        REQUIRE_SET_PASSWORD: 143,
        REQUIRE_COMPLETE_REGISTRATION: 144,
        REQUIRE_LINK_FACEBOOK: 145,
        REQUIRE_HTTPS: 146,
        REQUIRE_ACCEPT_TERM: 160,
        NEED_REDIRECT_TO_PAYMENT: 162,
        RECAPTCHA_VALIDATION_FAIL: 157,
        REMOVE_TEAM_CAPTAIN_ERROR: 170,
        REQUIRE_SSO_SET_PASSWORD: 176,
        ALREADY_SCHOOL_MEMBER: 179,
        PENDING_JOIN_SCHOOL_REQUEST: 180,
        HAVE_NO_JOINED_SCHOOL: 183,
        NOT_SESSION_MEMBER: 185,
        NOT_GAME_MEMBER: 186,
        REQUIE_VALID_TEAM: 187,
        TEAM_DOES_NOT_EXIST: 188,
        NEED_TO_CHOOSE_PACKAGE: 189,
        NOT_EVENT_MEMBER: 191,
        CAN_JOIN_AS_NOMAD: 192,
        NEED_CREATE_NEW_ACCOUNT: 202,
        SID_IN_USE: 400,
        SID_PENDING_REQUEST: 401,
        SID_VERIFY_FAILED: 402,
        SID_NOT_MATCH: 404,
        SID_NOT_IN_SHEET: 405,
        FITNESS_DISABLED: 600,
        CLUB_DISABLED: 700,
        CLIENT_TIMEOUT: -999,
        BACKGROUND_TASK_NOT_READY: -1000,
    },
    MOBILE_UID_PREFIX: "mobile",
    PASSWORD_REGEX: /^(?=.*[A-Za-z])(?=.*\d)[\d\D]{8,}$/, //数字和字母组合，长度至少8位
    HOMEWORK_RESULT_GRADE: {
        PERFECT: "It's Perfect",
        EXCELLENT: "Excellent !",
        GOOD: "Very Good",
        PITY: "What a Pity",
    },
    ENTITY_TYPE: {
        PORTAL: "portal",
        MEMBER: "member",
        ACCOUNT: "account",
        NETWORK: "network",
        SPORT: "sport",
        LEAGUE: "league",
        DIVISION: "division",
        REGULARGAME: "regulargame",
        PLAYOFFGAME: "playoffgame",
        TEAM: "team",
        CLUB: "club",
        FITNESS: "fitness",
        INTRAMURAL: "intramural",
        PLAYOFF: "playoff",
        CONVERSATION: "conversation",
        FITNESSSESSION: "fitnesssession",
    },
    PAYMENT: {
        CURRENCY_TYPE: {
            USD: "USD",
            CNY: "CNY",
        },
        GATEWAY: {
            BRAINTREE: 0,
            WECHAT: 1,
            STRIPNET: 2,
        },
        ORDER_STATUS: {
            NEW: 0,
            COMPLETED: 1,
            TO_BE_PROCESSED: 2,
            PROCESSING_FAILURE: 3,
            NOTIFICATION_RECEIVED: 4,
            VERIFICATION_FAILED: 5,
            ORDER_DENIED: 6,
            TO_BE_DENIED: 7,
            PENDING: 8,
        },
    },
    SPEECH_CONSTANT: {
        DEFAULT_RECOGNIZE_TIME_OUT: 180,
        MAX_RECOGNIZE_TIME_OUT: 180,
        DEFAULT_WORD_RECOGNIZE_TIME_OUT: 20,
        MAX_WORD_RECOGNIZE_TIME_OUT: 40,
    },
    SPEECH_LANG: {
       en: "en-US",
       zh: "zh-CN"
    },
    COURSE_CATEGORY: {
        POEM: 0,
        NOVEL: 1,
        CHILDREN_STORY: 2,
        SPOKEN_LANGUAGE: 3,
        IDIOMSTORY: 4,
        CLASSIC_ARTICLE: 5,
    },
    AnswerTypeEnum: {
        AnswerType_DirectInput: 1,
        AnswerType_UploadFile: 2,
        AnswerType_DirectInputOrUploadFile: 3, // 1|2
        AnswerType_Audio: 4,
        //AnswerType_DirectInputOrAudio: 5, // 1|4
        AnswerType_UploadImage: 8,
        //AnswerType_DirectInputOrUploadImage: 9, // 1|8
    },
    AnswerTypeCookieKey: {
        Audio: "AnswerType_Audio",
        UploadImage: "AnswerType_UploadImage",
    },
    UnitViewModeEnum: {
        Normal: 0,
        SimpleBatch: 1,
        Homework: 2,
    },
    DefaultPageSize: 50,
};
