/**
 * Created by JIA on 2020/7/11.
 * A Helper for Parse and Convert (back) the Mark Text.
 *     For now, we DO NOT support nest mark(mark in mark content),
 *         also if multi mark, you should use something like \h\u{content}
 *    e.g.: This will be \h{highlight} , and a input \i{1|defaultValue}, a full line input \i{f}
 *          we would like support MULTI sub command for word mark
 *      \h -> highlight text ,in {} is the content
 *      \i -> input, in {1|defaultValue} the "1" is the default length, the "defaultValue" is the defaultValue of input, if the length is a "f" it will fill rest of current line
 *      \p -> bottom input, text that click can edit, will pop a bottom pop area,
 *              in {1|defaultValue|placeholder} the "1" is the input row count, the "defaultValue" is the defaultValue of input, last part is input placeholder
 *      \u -> underline text, in {} is the content
 *      \b -> bold text, in {} is the content
 *      \b\u -> bold and underline text
 *      \s -> skip text, in {} is the content
 *     Also we will have a Line level Mark, this mark would apply to all text in this line, this mark should only at the start of the string and has a end flag |
 *         we would like support MULTI sub command for line mark
 *      \l|  -> align left, no need it is a default
 *      \c| -> c -> all text in this line will be center align
 *      \r| -> r -> all text in this line will be right align
 *      \c\i|  ->  c & s -> center align AND indent at left
 *      \d{a}  \d{b}  -> d means dialog with role A(left) or B(right)
 *          Note:: For sentence command design, We will not allow | in setting part like the word command Input. to avoid the end | conflict. better use another flag like , or ;
 *         e.g. \c|this line will be centered
 */
import Constants from "../config/Constants";
import _ from "lodash";
import { isNumeric, isChineseCharacters, isPunctuation } from "./Helper";
import Colors from "../mobile/theme/colors";
import { StyleSheet } from "react-native-web";

/**
 * the map from enum to mark of word
 * @type {{[p: string]: string, [p: number]: string}}
 */
const wordMarkMap = {
    [Constants.TEXT_MARK_TYPE.HIGHLIGHT]: "h",
    [Constants.TEXT_MARK_TYPE.INPUT]: "i",
    [Constants.TEXT_MARK_TYPE.UNDERLINE]: "u",
    [Constants.TEXT_MARK_TYPE.BOLD]: "b",
    [Constants.TEXT_MARK_TYPE.POP_INPUT]: "p",
    [Constants.TEXT_MARK_TYPE.ICON]: "c",
    [Constants.TEXT_MARK_TYPE.SKIP]: "s",
    [Constants.TEXT_MARK_TYPE.KEYWORD]: "k",
    [Constants.TEXT_MARK_TYPE.TYPO]: "t",
    [Constants.TEXT_MARK_TYPE.MISSPELT]: "m",
    [Constants.TEXT_MARK_TYPE.NUMBER]: "n",
    [Constants.TEXT_MARK_TYPE.ERRORLETTER]: "e",
    [Constants.TEXT_MARK_TYPE.WRITE_INPUT]: "w",
    [Constants.TEXT_MARK_TYPE.PLAIN_TEXT]: "",
};
const wordMarkReMap = {
    h: Constants.TEXT_MARK_TYPE.HIGHLIGHT,
    i: Constants.TEXT_MARK_TYPE.INPUT,
    u: Constants.TEXT_MARK_TYPE.UNDERLINE,
    b: Constants.TEXT_MARK_TYPE.BOLD,
    p: Constants.TEXT_MARK_TYPE.POP_INPUT,
    c: Constants.TEXT_MARK_TYPE.ICON,
    s: Constants.TEXT_MARK_TYPE.SKIP,
    k: Constants.TEXT_MARK_TYPE.KEYWORD,
    t: Constants.TEXT_MARK_TYPE.TYPO,
    m: Constants.TEXT_MARK_TYPE.MISSPELT,
    n: Constants.TEXT_MARK_TYPE.NUMBER,
    e: Constants.TEXT_MARK_TYPE.ERRORLETTER,
    w: Constants.TEXT_MARK_TYPE.WRITE_INPUT,
    "": Constants.TEXT_MARK_TYPE.PLAIN_TEXT,
};
/**
 * the map from enum to mark of sentence
 * @type {{[p: string]: string, [p: number]: string}}
 */
const sentenceMarkMap = {
    [Constants.SENTENCE_MARK_TYPE.ALIGN_CENTER]: "c",
    [Constants.SENTENCE_MARK_TYPE.ALIGN_RIGHT]: "r",
    [Constants.SENTENCE_MARK_TYPE.LEFT_INDENT]: "i",
    [Constants.SENTENCE_MARK_TYPE.DIALOG]: "d",
    [Constants.SENTENCE_MARK_TYPE.PLAIN_TEXT]: "",
};
const sentenceMarkReMap = {
    c: Constants.SENTENCE_MARK_TYPE.ALIGN_CENTER,
    r: Constants.SENTENCE_MARK_TYPE.ALIGN_RIGHT,
    i: Constants.SENTENCE_MARK_TYPE.LEFT_INDENT,
    d: Constants.SENTENCE_MARK_TYPE.DIALOG,
    "": Constants.SENTENCE_MARK_TYPE.PLAIN_TEXT,
};

/**
 * extract the settings from setting string for input(input/pop input)
 * @param settingString
 * @param defaultFirstNumber  -> default width/ row
 * @param fitFirstNumber  -> for input case we will fit the first number by the default value, for pop no need
 * @returns {{defaultValue: string, firstNumber: number, placeholder: string}}
 */
const extractInputSettings = (settingString, defaultFirstNumber, fitFirstNumber) => {
    const paramArray = settingString.split("|");
    let initialCharCount = defaultFirstNumber;
    let defaultValue = "";
    let placeholder = "";
    let isInFitInitialCharCountCase = false;
    if (paramArray.length === 1) {
        defaultValue = paramArray[0];
        //set init count by the default value when not provided

        isInFitInitialCharCountCase = true;
    } else if (paramArray.length === 2) {
        //if you set string|string and the first part is NOT a number, we will fill it with default value and place holder
        if (paramArray[0] && isNumeric(paramArray[0])) {
            initialCharCount = paramArray[0];
            defaultValue = paramArray[1];
        } else {
            defaultValue = paramArray[0];
            placeholder = paramArray[1];
            //set init count by the default value when not provided
            isInFitInitialCharCountCase = true;
        }
    } else if (paramArray.length === 3) {
        defaultValue = paramArray[1];
        placeholder = paramArray[2];
        if (paramArray[0] && isNumeric(paramArray[0])) {
            initialCharCount = paramArray[0];
        } else {
            //set init count by the default value when not provided
            isInFitInitialCharCountCase = true;
        }
    }
    //now we can set some default values as right choice
    if (defaultValue.indexOf("@") > -1) {
        const defaultValueArray = defaultValue.split("@");
        if (defaultValue.length > 1) {
            defaultValue = defaultValueArray;
        }
    }

    const defaultValueToSHow = GetDefaultAnswerToShow(defaultValue);
    //set init count by the default value when not provided
    if (fitFirstNumber && isInFitInitialCharCountCase) {
        if (defaultValue && _.isString(defaultValue) && defaultValue.length > 0) {
            initialCharCount = defaultValueToSHow.length;
        } else if (placeholder && _.isString(placeholder) && placeholder.length > 0) {
            initialCharCount = placeholder.length;
        }
    }

    return {
        firstNumber: initialCharCount,
        defaultValue: defaultValue,
        placeholder: placeholder,
        defaultValueToShow: defaultValueToSHow,
    };
};

/**
 * a common method for detect user input is right or not
 *  can fit the multi-right answer case
 * @param userInput
 * @param defaultValue
 * @returns {boolean}
 */
const IsAnswerRightForInput = (userInput, defaultValue) => {
    if (_.isString(defaultValue)) {
        return userInput === defaultValue;
    } else if (_.isArray(defaultValue)) {
        return defaultValue.indexOf(userInput) > -1;
    }

    return false;
};

/**
 * show first item if is array
 * @param defaultValue
 * @returns {*|string}
 * @constructor
 */
const GetDefaultAnswerToShow = (defaultValue) => {
    if (_.isString(defaultValue)) {
        return defaultValue;
    } else if (_.isArray(defaultValue)) {
        return defaultValue.length > 0 ? defaultValue[0] : "";
    }

    return defaultValue;
};

/**
 * extract settings from setting string for icon
 * @param {string} settingString
 * @returns {{name:string,size:number,color:string}}
 */
const extractIconSettings = (settingString) => {
    const paramArray = settingString.split("|");
    let name = paramArray[0];
    if (!_.isEmpty(name)) {
        name =
            (_.startsWith(name, "fa-") ? "" : "fa") +
            name
                .split("-")
                .map((str) => {
                    if (str.length !== 0 && str !== "fa") {
                        let k = str.charAt(0);
                        k = k.toUpperCase();
                        str = str.replace(str.charAt(0), k);
                    }
                    return str;
                })
                .join("");
    }
    return {
        name: name,
        size: parseInt(paramArray[1] || "25", 0),
        color: paramArray[2],
    };
};

/**
 * extract settings from setting string for Number
 * @param {string} settingString
 * @returns {{chnText:string,numText:string}}
 */
const extractNumberSettings = (settingString) => {
    const paramArray = settingString.split("|");
    return {
        chnText: paramArray.length > 1 ? paramArray[1] : paramArray[0],
        numText: paramArray[0],
    };
};

/**
 * extract settings from setting string for typo
 * @param {string} settingString
 * @returns {{rightText:string,errText:string}}
 */
const extractErrorLetterSettings = (settingString) => {
    const paramArray = settingString.split("|");
    return {
        rightText: paramArray.length > 1 ? paramArray[1] : paramArray[0],
        errText: paramArray[0],
    };
};

/**
 * parse single part of a mark text
 *      e.g. \i\b{Here is content}
 *          command is the "\i\b"
 *          content is "Here is content"
 * @param command
 * @param content
 * @returns {{text: string, type: number, setting: {}}|{text: string, type: number, setting: {defaultValue: string, initialCharCount: number}}|{text: *, type: number, setting: {}}}
 * @constructor
 */
const ParseMarkTextStrPart = (command, content) => {
    if (_.startsWith(command, "\\")) {
        let charStack = [];
        let commandArray = [];
        //for this one we only parse the part behind \ and before any white space \[Parse Part]{}
        for (let i = 0; i < command.length; i++) {
            const tmpChar = command[i];

            //push every command
            if (tmpChar === "\\") {
                if (charStack.length > 1) {
                    const pop = _.trimStart(charStack.join(""), "\\"); //delete left \
                    if (_.has(wordMarkReMap, pop)) {
                        commandArray.push(wordMarkReMap[pop]);
                    }

                    //pop all
                    charStack = [];
                }
            }

            charStack.push(tmpChar);
        }

        //the final part.
        if (charStack.length > 1) {
            const pop = _.trimStart(charStack.join(""), "\\"); //delete left \
            if (_.has(wordMarkReMap, pop)) {
                commandArray.push(wordMarkReMap[pop]);
            }

            //pop all
            charStack = [];
        }

        if (commandArray.length > 0) {
            //union all flags
            const flags = UnionAllEnum(commandArray);

            if (EnumHasFlag(flags, Constants.TEXT_MARK_TYPE.INPUT)) {
                let initialCharCount = 4;
                //{
                //   initialCharCount: initialCharCount,
                //   defaultValue: defaultValue,
                //   placeholder: placeholder,
                // }
                const setting = extractInputSettings(content, initialCharCount, true);

                return {
                    type: Constants.TEXT_MARK_TYPE.INPUT,
                    setting: {
                        initialCharCount: setting.firstNumber,
                        defaultValue: setting.defaultValue,
                        placeholder: setting.placeholder,
                    },
                    text: "",
                    orgContent: content,
                };
            } else if (EnumHasFlag(flags, Constants.TEXT_MARK_TYPE.POP_INPUT)) {
                let initialRowCount = 1;
                //{
                //   initialCharCount: initialCharCount,
                //   defaultValue: defaultValue,
                //   placeholder: placeholder,
                // }
                const setting = extractInputSettings(content, initialRowCount, false);

                return {
                    type: flags, //you can have multiple for this since it is a text
                    setting: {
                        initialRowCount: setting.firstNumber,
                        defaultValue: setting.defaultValue,
                        placeholder: setting.placeholder,
                    },
                    text: setting.defaultValueToShow,
                    orgContent: content,
                };
            } else if (EnumHasFlag(flags, Constants.TEXT_MARK_TYPE.WRITE_INPUT)) {
                let initialRowCount = 1;
                //{
                //   initialCharCount: initialCharCount,
                //   defaultValue: defaultValue,
                //   placeholder: placeholder,
                // }
                const setting = extractInputSettings(content, initialRowCount, false);

                return {
                    type: Constants.TEXT_MARK_TYPE.WRITE_INPUT,
                    setting: {
                        initialCharCount: setting.firstNumber,
                        defaultValue: "", // TODO: use setting.defaultValue
                        placeholder: "", // TODO: use setting.placeholder
                    },
                    text: "",
                    orgContent: content,
                };
            } else if (EnumHasFlag(flags, Constants.TEXT_MARK_TYPE.ICON)) {
                const setting = extractIconSettings(content);

                return {
                    type: flags,
                    setting: setting,
                    text: content,
                    orgContent: content,
                };
            } else if (EnumHasFlag(flags, Constants.TEXT_MARK_TYPE.NUMBER)) {
                const setting = extractNumberSettings(content);

                return {
                    type: flags,
                    setting: setting,
                    text: setting.numText,
                    orgContent: content,
                };
            } else if (EnumHasFlag(flags, Constants.TEXT_MARK_TYPE.ERRORLETTER)) {
                const setting = extractErrorLetterSettings(content);

                return {
                    type: flags,
                    setting: setting,
                    text: setting.errText,
                    orgContent: content,
                };
            }

            return {
                type: flags,
                setting: {},
                text: content,
                orgContent: content,
            };
        }
    }

    return {
        type: Constants.TEXT_MARK_TYPE.PLAIN_TEXT,
        setting: {},
        text: `${command}{${content}}`,
    };
};

/**
 * if the last one of list is plaintext and we add a plaintext, we will merge these 2 unit to one
 * @param array
 * @param newItem
 */
const addWordToArray = (array, newItem) => {
    let needPush = true;
    if (array.length > 0 && newItem.type === Constants.TEXT_MARK_TYPE.PLAIN_TEXT) {
        let lastItem = array[array.length - 1];
        if (lastItem.type === Constants.TEXT_MARK_TYPE.PLAIN_TEXT) {
            lastItem.text = lastItem.text + newItem.text; //combine to last one
            needPush = false;
        }
    }
    if (needPush) {
        array.push(newItem);
    }
};

/**
 * we do not consider nest for now
 *      use array as a stack to parse strings
 * @param text
 * @returns {[]}
 * @constructor
 */
const ParseMarkText = (text) => {
    let result = [];

    if (text && text.length > 0) {
        let helperStack = [];
        let commandStack = [];
        let contentStack = [];

        let pushCommandNow = false;
        let pushContentNow = false;
        for (let i = 0; i < text.length; i++) {
            let tmpChar = text[i];

            //start match and pop previous as a plain text
            //if is something like \i{ , we got the start
            if (!pushCommandNow && tmpChar === "\\") {
                if (!pushContentNow && i !== 0) {
                    //no need add an empty text node
                    if (helperStack.length > 0) {
                        addWordToArray(result, {
                            type: Constants.TEXT_MARK_TYPE.PLAIN_TEXT,
                            setting: {},
                            text: helperStack.join(""),
                        });
                    }

                    commandStack = [];
                    contentStack = [];
                    helperStack = [];
                }
                pushCommandNow = true;
            }
            helperStack.push(tmpChar);

            //{ is the start of content
            if (pushCommandNow && tmpChar === "{") {
                //command part done, we will do parse if all are done
                // here we just go into content parse
                pushContentNow = true;
                pushCommandNow = false;
            }

            //} is end flag, for now we ignore the input, so we do not consider the content.
            if (pushContentNow && tmpChar === "}") {
                pushContentNow = false;

                //we are done here for one part
                //validate the command part? or ignore not valid one
                //
                const wordObj = ParseMarkTextStrPart(commandStack.join(""), _.trimStart(contentStack.join(""), "{"));
                helperStack = [];
                commandStack = [];
                contentStack = [];
                addWordToArray(result, wordObj);
            }

            if (pushCommandNow) {
                commandStack.push(tmpChar);
            }

            if (pushContentNow) {
                contentStack.push(tmpChar);
            }
        }

        if (helperStack.length > 0) {
            result.push({
                type: Constants.TEXT_MARK_TYPE.PLAIN_TEXT,
                setting: {},
                text: helperStack.join(""),
            });
        }
    }
    return result;
};

/**
 * parse the whole sentence
 * @param sentence
 * @returns {{words: [], type: number, content: string}|{words: [], type: number, content: string}|{words: [], type: number, content: *}}
 * @constructor
 */
const ParseSentence = (sentence) => {
    if (sentence.indexOf("\n") > -1) {
        console.warn("Only support single line");
        sentence = _.replace(sentence, /\n/g, ""); //just replace \n
    }
    const parseObj = ParseSentenceMarkAndLeftPart(sentence);

    if (!_.isEmpty(parseObj.content) && parseObj.content.length > 0) {
        //fill word
        parseObj.words = ParseMarkText(parseObj.content);
    }
    return parseObj;
};

/**
 * first parse the sentence level command and return the left part as content
 *  Now will support setting/content for sentence level command, e.g:  \d{a}|dialog from a
 *  we will ignore command behind content {} part, so {}| should be the last part of sentence command
 *   Note:: For simplify we will only process the first {} part before |, and if the | is in right of "{" that not closed, we will treat it as invalid
 * @param sentence
 * @returns {{words: [], type: number, content: string, setting: Object}|{words: [], type: number, content: *, setting: Object}}
 * @constructor
 */
const ParseSentenceMarkAndLeftPart = (sentence) => {
    if (_.isEmpty(sentence) || !_.isString(sentence)) {
        return {
            type: Constants.SENTENCE_MARK_TYPE.PLAIN_TEXT,
            content: "",
            setting: {},
            words: [],
        };
    }

    if (_.startsWith(sentence, "\\")) {
        let charStack = [];
        let contentStack = [];
        let commandArray = [];
        let hasEndFlag = false;
        let invalid = false;
        let endFlagIndex = 0;
        let commandEnd = false;
        let contentStart = false;
        let contentClosed = false;
        //for this one we only parse the part behind \ and before any white space \[Parse Part]{}
        for (let i = 0; i < sentence.length; i++) {
            const tmpChar = sentence[i];

            //push every command
            if (!commandEnd && tmpChar === "\\") {
                if (charStack.length > 1) {
                    const pop = _.trimStart(charStack.join(""), "\\"); //delete left \
                    if (_.has(sentenceMarkReMap, pop)) {
                        commandArray.push(sentenceMarkReMap[pop]);
                    }

                    //pop all
                    charStack = [];
                }
            }
            //if we got { before |,  there are a setting for command
            //if we have a { , we must have a } before } as pair!, otherwise we may in the word command input part. \\i{3|default value|place holder}
            if (tmpChar === "{") {
                contentStart = true;
                commandEnd = true;
                contentClosed = false;
            }
            if (contentStart && tmpChar === "}") {
                contentStart = false;
                contentClosed = true;
            }

            //there are end flag. we are done
            if (tmpChar === "|") {
                endFlagIndex = i;
                hasEndFlag = true;
                break;
            }

            if (contentStart && tmpChar !== "{") {
                contentStack.push(tmpChar);
            }

            if (!commandEnd) {
                charStack.push(tmpChar);
            }
        }

        if (contentStart && !contentClosed) {
            invalid = true;
        }

        if (!invalid) {
            if (charStack.length > 1) {
                const pop = _.trimStart(charStack.join(""), "\\"); //delete left \
                if (_.has(sentenceMarkReMap, pop)) {
                    commandArray.push(sentenceMarkReMap[pop]);
                }
            }

            if (hasEndFlag && commandArray.length > 0) {
                const leftString = sentence.substr(endFlagIndex + 1);
                //union all flags
                const flags = UnionAllEnum(commandArray);
                const content = _.trimEnd(_.trimStart(contentStack.join(""), "{"), "}");
                let setting = {};
                if (EnumHasFlag(flags, Constants.SENTENCE_MARK_TYPE.DIALOG)) {
                    setting = {
                        role: content, //a or b
                    };
                }
                return {
                    type: flags,
                    setting: setting,
                    content: leftString,
                    words: [],
                };
            }
        }
    }

    return {
        type: Constants.SENTENCE_MARK_TYPE.PLAIN_TEXT,
        content: sentence,
        setting: {},
        words: [],
    };
};

/**
 * Union all enum
 * @param enumArray
 * @returns {number}
 * @constructor
 */
const UnionAllEnum = (enumArray) => {
    if (_.isArray(enumArray)) {
        //get distinct array
        const targetArray = _.uniq(enumArray);
        if (targetArray.length > 1) {
            let returnResult = targetArray[0];
            _.each(targetArray, (item, i) => {
                if (_.isNumber(item) && item !== 1 && i > 0) {
                    // eslint-disable-next-line no-bitwise
                    returnResult = returnResult | item;
                }
            });

            return returnResult;
        }
    }

    return enumArray.length > 0 ? enumArray[0] : 1;
};

/**
 * check the enum value contains flag
 * @param targetEnum
 * @param flagEnum
 * @returns {boolean|number}
 * @constructor
 */
const EnumHasFlag = (targetEnum, flagEnum) => {
    if (_.isNumber(targetEnum) && _.isNumber(flagEnum)) {
        // eslint-disable-next-line no-bitwise
        return targetEnum & flagEnum;
    }

    return false;
};

/**
 *  Get the plain text from mark string, for piyin case use
 * @param sentence
 * @returns {string}
 * @constructor
 */
const GetPlainTextFromSentence = (sentence) => {
    const parsedSentence = ParseSentence(sentence);
    let result = "";
    _.each(parsedSentence.words, (item) => {
        if (EnumHasFlag(item.type, Constants.TEXT_MARK_TYPE.INPUT)) {
            //if have default value, we just add default value
            if (item.setting.defaultValue && item.setting.defaultValue.length > 0) {
                result += item.setting.defaultValue;
            } else {
                result += _.repeat(" ", _.isNumber(item.setting.initialCharCount) ? item.setting.initialCharCount : 4); //should we add space for input
            }
        } else if (EnumHasFlag(item.type, Constants.TEXT_MARK_TYPE.POP_INPUT)) {
            //if have default value, we just add default value
            if (item.setting.defaultValue && item.setting.defaultValue.length > 0) {
                result += item.setting.defaultValue;
            }
        } else if (EnumHasFlag(item.type, Constants.TEXT_MARK_TYPE.WRITE_INPUT)) {
            //if have default value, we just add default value
            if (item.setting.defaultValue && item.setting.defaultValue.length > 0) {
                result += item.setting.defaultValue;
            }
        } else {
            result += item.text;
        }
    });
    return result;
};

/**
 *  Get the plain text from mark string, for speech recognize case use
 *  this will convert NUMBER type to the chnText
 *      e.g. \n{1989|一九八九}  => 一九八九
 *           \n{1989|一千九百八十九}  => 一千九百八十九
 * @param sentence
 * @returns {string}
 * @constructor
 */
const GetPlainTextFromSentenceForSpeechUse = (sentence) => {
    const parsedSentence = ParseSentence(sentence);
    let result = "";
    _.each(parsedSentence.words, (item) => {
        if (EnumHasFlag(item.type, Constants.TEXT_MARK_TYPE.INPUT)) {
            //if have default value, we just add default value
            if (item.setting.defaultValue && item.setting.defaultValue.length > 0) {
                result += item.setting.defaultValue;
            } else {
                result += _.repeat(" ", _.isNumber(item.setting.initialCharCount) ? item.setting.initialCharCount : 4); //should we add space for input
            }
        } else if (EnumHasFlag(item.type, Constants.TEXT_MARK_TYPE.POP_INPUT)) {
            //if have default value, we just add default value
            if (item.setting.defaultValue && item.setting.defaultValue.length > 0) {
                result += item.setting.defaultValue;
            }
        } else if (EnumHasFlag(item.type, Constants.TEXT_MARK_TYPE.WRITE_INPUT)) {
            //if have default value, we just add default value
            if (item.setting.defaultValue && item.setting.defaultValue.length > 0) {
                result += item.setting.defaultValue;
            }
        } else if (EnumHasFlag(item.type, Constants.TEXT_MARK_TYPE.NUMBER)) {
            //if have default value, we just add default value
            if (item.setting.chnText) {
                result += item.setting.chnText;
            } else {
                result += item.text;
            }
        } else if (EnumHasFlag(item.type, Constants.TEXT_MARK_TYPE.ERRORLETTER)) {
            //if have default value, we just add default value
            if (item.setting.rightText) {
                result += item.setting.rightText;
            } else {
                result += item.text;
            }
        } else {
            result += item.text;
        }
    });
    return result;
};

const GetPlainTextFromSentences = (sentences) => {
    if (!sentences) return "";
    let result = [];
    sentences.split("\n").forEach((sentence) => {
        result.push(GetPlainTextFromSentence(sentence));
    });
    return _.join(result, "\n");
};

/**
 *  Get the count of type from mark string
 * @param sentence
 * @param type
 * @returns {number}
 * @constructor
 */
const GetCountOfMarkTypeFromSentence = (sentence, type) => {
    const parsedSentence = ParseSentence(sentence);
    let result = 0;
    _.each(parsedSentence.words, (item) => {
        if (EnumHasFlag(item.type, type)) {
            result++;
        }
    });
    return result;
};

const GetContentListOfMarkTypeFromSentence = (sentence, type) => {
    const parsedSentence = ParseSentence(sentence);
    let result = [];
    _.each(parsedSentence.words, (item) => {
        if (EnumHasFlag(item.type, type)) {
            result.push(item.text);
        }
    });
    return result;
};

const GetWordsOfMarkTypeFromSentence = (sentence, type) => {
    const parsedSentence = ParseSentence(sentence);
    let result = [];
    _.each(parsedSentence.words, (item) => {
        if (EnumHasFlag(item.type, type)) {
            result.push(item);
        }
    });
    return result;
};

/**
 * count how many chinese chars in string, used for syllables check
 * @param sentence
 * @constructor
 */
const CountChineseCharsCountInString = (sentence) => {
    return _.filter(sentence, (item) => /^[\u4E00-\u9FA5]+$/.test(item)).length;
};

/**
 * convert type enum to string
 *  consider the flag condition, so it may have more than one \x
 *  this can support sentence/word mark by the markDic you send in
 * @param markDic
 * @param type
 * @returns {string}
 * @constructor
 */
const ConvertMarkTypeToString = (markDic, type) => {
    let command = "";

    _.each(markDic, (val, key) => {
        key = parseInt(key);
        if (key !== Constants.TEXT_MARK_TYPE.PLAIN_TEXT && key !== Constants.SENTENCE_MARK_TYPE.PLAIN_TEXT) {
            if (EnumHasFlag(type, key)) {
                command += `\\${val}`;
            }
        }
    });

    return command;
};

/**
 * Convert parsed mark back to mark string
 * @param parsedWord
 * @returns {string|*}
 * @constructor
 */
const ConvertParsedWordToString = (parsedWord) => {
    const wordCommand = ConvertMarkTypeToString(wordMarkMap, parsedWord.type);
    if (parsedWord.type !== Constants.TEXT_MARK_TYPE.PLAIN_TEXT && wordCommand.length > 0) {
        return parsedWord.orgContent ? `${wordCommand}{${parsedWord.orgContent}}` : `${wordCommand}{${parsedWord.text}}`;
    } else {
        return parsedWord.text;
    }
};

/**
 *  convert parsed sentence to mark text string
 * @param parsedSentence
 * @returns {string}
 * @constructor
 */
const ConvertParsedSentenceToString = (parsedSentence) => {
    const command = ConvertMarkTypeToString(sentenceMarkMap, parsedSentence.type);
    let sentenceContentPart = "";
    if (EnumHasFlag(parsedSentence.type, Constants.SENTENCE_MARK_TYPE.DIALOG)) {
        sentenceContentPart = `{${parsedSentence.role}}`;
    }
    let wordPart = _.map(parsedSentence.words, (item) => ConvertMarkTypeToString(item)).join("");
    if (parsedSentence.type !== Constants.SENTENCE_MARK_TYPE.PLAIN_TEXT && command.length > 0) {
        return `${command}${sentenceContentPart}|${wordPart}`;
    } else {
        return wordPart;
    }
};

/**
 * get parsed setting for sentence
 * @param setting -> can be object(from parse) or JSON string(from server)
 * @returns {{}}
 * @constructor
 */
const ParseSetting = (setting) => {
    let parsedSetting = null;
    if (setting) {
        if (_.isString(setting) && setting !== "") {
            try {
                parsedSetting = JSON.parse(setting);
            } catch (e) {}
        } else if (_.isPlainObject(setting)) {
            parsedSetting = setting;
        }
    }
    return parsedSetting;
};

/**
 * get style from command for sentence
 * @param sentenceCommand
 * @param setting  -> can be object(from parse) or JSON string(from server)
 * @returns {{
 *     sentenceClassName: "",
 * }}
 * if return contains sentenceClassName we will put it as class for the sentence component since some style that contains : css can not be apply inline without a 3rd party lib
 * @constructor
 */
const GetSentenceStyleBySentenceCommand = (sentenceCommand, setting) => {
    const styles = {
        centerAligned: {
            textAlign: "center",
            alignSelf: "center",
            justifyContent: "center", //web seems default has flex row
            flex: 1,
            //margin: "0 auto",
        },
        rightAligned: {
            textAlign: "right",
            alignSelf: "flex-end",
            justifyContent: "flex-end",
            flex: 1,
        },
        leftIndent: {
            //paddingLeft: "48px",
            //textIndent: "2em",
            sentenceClassName: "markLeftIndent", // for sentence component, it use div inside so we must apply textIndent for the first child to make it work, we use class for this
        },
        dialogRole: {
            padding: "12px",
            borderRadius: "5px",
            maxWidth: "240px",
            flexWrap: "wrap",
        },
        dialogRoleA: {
            backgroundColor: "#f2f2f2",
        },
        dialogRoleB: {
            backgroundColor: "#ffdfd5",
        },
    };
    let sentenceStyle = {};
    let parsedSetting = ParseSetting(setting);

    if (_.isNumber(sentenceCommand)) {
        if (EnumHasFlag(sentenceCommand, Constants.SENTENCE_MARK_TYPE.ALIGN_CENTER)) {
            sentenceStyle = { ...sentenceStyle, ...styles.centerAligned };
        } else if (EnumHasFlag(sentenceCommand, Constants.SENTENCE_MARK_TYPE.ALIGN_RIGHT)) {
            sentenceStyle = { ...sentenceStyle, ...styles.rightAligned };
        }

        if (EnumHasFlag(sentenceCommand, Constants.SENTENCE_MARK_TYPE.LEFT_INDENT)) {
            sentenceStyle = { ...sentenceStyle, ...styles.leftIndent };
        }
        if (EnumHasFlag(sentenceCommand, Constants.SENTENCE_MARK_TYPE.DIALOG)) {
            sentenceStyle = { ...sentenceStyle, ...styles.dialogRole };
            if (parsedSetting && parsedSetting.role) {
                if (_.toLower(parsedSetting.role) === "a") {
                    sentenceStyle = { ...sentenceStyle, ...styles.dialogRoleA };
                } else if (_.toLower(parsedSetting.role) === "b") {
                    sentenceStyle = { ...sentenceStyle, ...styles.dialogRoleB };
                }
            }
        }
    }
    return sentenceStyle;
};

/**
 * get style from command for word
 * @param wordCommand
 * @returns {{}}
 * @constructor
 */
const GetWordStyleBySentenceCommand = (wordCommand) => {
    const styles = {
        bold: {
            fontWeight: "bold",
        },
        highlight: {
            color: "#1785af",
        },
        underline: {
            //note that || key === "textDecorationLine" is not look good since every char has a space
            // textDecorationLine: "underline",
            borderBottomWidth: 1,
            //borderBottomColor: "#525f7f", no need set color, it auto follow font color
            borderBottomStyle: "solid",
        },
        skip: {
            color: "transparent",
            width: "0px",
            display: "none",
        },
        keyword: {
            color: "#1785af",
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
        },
    };
    let style = {};

    if (EnumHasFlag(wordCommand, Constants.TEXT_MARK_TYPE.BOLD)) {
        style = { ...style, ...styles.bold };
    }
    if (EnumHasFlag(wordCommand, Constants.TEXT_MARK_TYPE.HIGHLIGHT)) {
        style = { ...style, ...styles.highlight };
    }
    if (EnumHasFlag(wordCommand, Constants.TEXT_MARK_TYPE.UNDERLINE)) {
        style = { ...style, ...styles.underline };
    }
    if (EnumHasFlag(wordCommand, Constants.TEXT_MARK_TYPE.SKIP)) {
        style = { ...style, ...styles.skip };
    }
    if (EnumHasFlag(wordCommand, Constants.TEXT_MARK_TYPE.KEYWORD)) {
        style = { ...style, ...styles.keyword };
    }
    return style;
};

/**
 * 检查光标是否在关键词中
 * @param {string} text 文本
 * @param {number} pos 光标位置
 */
const cursorInMarks = (text, pos, marks) => {
    for (let i = pos; i >= 0; i--) {
        //从光标处向前查找'\k{'
        if (text[i - 1] === "}") return false; //如果先找到'}', 退出循环
        if (text[i] === "{" && marks.includes(text[i - 1]) && text[i - 2] === "\\") {
            for (let j = pos; j < text.length; j++) {
                //从光标处向后查找'}'
                if (text[j] === "\\" && marks.includes(text[j + 1]) && text[j + 2] === "{") return false; //如果先找到'\k{', 退出循环
                if (text[j] === "}") return true;
            }
        }
    }
    return false;
};

/**
 * 判断关键字是否在选中文本中
 * @param {string} text
 * @param {number} posStart
 * @param {number} posEnd
 */
const marksInSelection = (text, posStart, posEnd, marks) => {
    for (let i = posStart; i < posEnd; i++) {
        //在开始和结束光标之间查找'\k{'
        let flag1 = text[i] === "\\" && marks.includes(text[i + 1]) && text[i + 2] === "{";
        let flag2 = marks.includes(text[i]) && text[i - 1] === "\\" && text[i + 1] === "{";
        let flag3 = text[i] === "{" && marks.includes(text[i - 1]) && text[i - 2] === "\\";
        let flag4 = text[i] === "}";
        if (flag1 || flag2 || flag3 || flag4) return true;
    }
    return false;
};

/**
 * 判断选中文本是否包含关键字
 *
 * @param {string} text 文本
 * @param {number} posStart 光标起始位置
 * @param {number} posEnd 光标结束位置
 */
const ContainMarksInSelection = (text, posStart, posEnd, marks = ["k", "n"]) => {
    text = (text || "").trim();
    if (!text) return false;

    let flag =
        cursorInMarks(text, posStart, marks) || //光标开始位置是否在关键词中间
        cursorInMarks(text, posEnd, marks) || //光标结束位置是否在关键词中间
        marksInSelection(text, posStart, posEnd, marks); //关键词是否在选中文本中间

    return flag;
};

/**
 * app specific
 * @param wordCommand
 * @returns {{}}
 * @constructor
 */
const GetWordContainerStyleBySentenceCommand = (wordCommand) => {
    const styles = StyleSheet.create({
        underline: {
            //note that || key === "textDecorationLine" is not look good since every char has a space
            // textDecorationLine: "underline",
            borderBottomWidth: 1,
            borderBottomColor: Colors.textPrimary,
        },
        underlineHighlight: {
            borderBottomColor: "#1785af",
        },
        keyword: {
            borderBottomWidth: 1,
            borderBottomColor: "#1785af",
        },
    });
    let commandContainerStyle = {};

    if (EnumHasFlag(wordCommand, Constants.TEXT_MARK_TYPE.UNDERLINE)) {
        commandContainerStyle = { ...commandContainerStyle, ...styles.underline };
    }

    if (EnumHasFlag(wordCommand, Constants.TEXT_MARK_TYPE.KEYWORD)) {
        commandContainerStyle = { ...commandContainerStyle, ...styles.keyword };
    }

    //handle the border line case
    if (EnumHasFlag(wordCommand, Constants.TEXT_MARK_TYPE.HIGHLIGHT) && EnumHasFlag(wordCommand, Constants.TEXT_MARK_TYPE.UNDERLINE)) {
        commandContainerStyle = { ...commandContainerStyle, ...styles.underlineHighlight };
    }
    return commandContainerStyle;
};

/**
 *  Get the default text from mark string
 * @param sentence
 * @returns {string}
 * @constructor
 */
const GetDefaultTextFromSentence = (sentence) => {
    const parsedSentence = ParseSentence(sentence);
    let result = [];
    _.each(parsedSentence.words, (item) => {
        if (item.type === Constants.TEXT_MARK_TYPE.INPUT) {
            result.push(item.setting.defaultValue);
        }
    });
    return result;
};

const JoinWords = (words, forEnLang = false) => {
    let result = "";
    let lastWord = null;
    // NOTE: 160是&nbsp;
    const spaces = [" ", "\n", "\t", String.fromCodePoint(160)];
    for (let i = 0; i < words.length; ++i) {
        let curWord = words[i];
        if (lastWord && curWord) {
            let lastFirstChar = lastWord[0];
            let lastChar = lastWord[lastWord.length - 1];
            let curChar = curWord[0];
            let curLastChar = curWord[curWord.length - 1];
            if (forEnLang) {
                // 英文每两个单词之间都加空格
                if (
                     lastFirstChar === "\\" && lastChar === "}"
                ) {
                    result += " ";
                }else if (
                     curChar === "\\" && (curLastChar === "}" || curWord.indexOf("}") > -1)
                ) {
                    result += " ";
                } else if (
                    spaces.indexOf(lastChar) >= 0 ||
                    spaces.indexOf(curChar) >= 0 ||
                    isChineseCharacters(lastChar) ||
                    isPunctuation(lastChar) ||
                    isChineseCharacters(curChar) ||
                    isPunctuation(curChar)
                ) {
                } else {
                    result += " ";
                }
            } else {
                // 暂时简单处理, 除了中文和标点之间不加空格, 其它字母词间加空格
                if (
                    spaces.indexOf(lastChar) >= 0 ||
                    spaces.indexOf(curChar) >= 0 ||
                    isChineseCharacters(lastChar) ||
                    isPunctuation(lastChar) ||
                    isChineseCharacters(curChar) ||
                    isPunctuation(curChar)
                ) {
                } else {
                    result += " ";
                }
            }
        }
        result += curWord;
        lastWord = curWord;
    }
    return result;
};

function ReplaceEnglishKeyword(wordStr, keyText, markText) {
    let result = '';
    let cur = 0;
    let len = wordStr.length;
    const spaces = [" ", "\n", "\t", String.fromCodePoint(160)];
    const englishReg = /[a-zA-Z]/;
    while (cur < len) {
        let i = wordStr.indexOf(keyText, cur);
        if (i >= 0) {
            result += wordStr.substring(cur, i);

            let prevChar = wordStr[i - 1];
            let nextChar = wordStr[i + keyText.length];
            // 单词前后是英文字母, 认为不是单独的单词, 不进行替换
            if ((prevChar && englishReg.test(prevChar)) || (nextChar && englishReg.test(nextChar))) {
                result += keyText;
            } else {
                result += markText;
            }

            cur = i + keyText.length;
        } else {
            result += wordStr.substring(cur, len);
            cur = len;
        }
    }
    return result;
}

export {
    EnumHasFlag,
    ParseMarkText,
    ParseSentence,
    ParseSetting,
    UnionAllEnum,
    JoinWords,
    GetPlainTextFromSentence,
    GetPlainTextFromSentenceForSpeechUse,
    ConvertParsedSentenceToString,
    ConvertParsedWordToString,
    CountChineseCharsCountInString,
    GetSentenceStyleBySentenceCommand,
    GetWordStyleBySentenceCommand,
    GetCountOfMarkTypeFromSentence,
    GetContentListOfMarkTypeFromSentence,
    GetWordsOfMarkTypeFromSentence,
    IsAnswerRightForInput,
    GetDefaultAnswerToShow,
    ContainMarksInSelection,
    GetPlainTextFromSentences,
    GetWordContainerStyleBySentenceCommand,
    GetDefaultTextFromSentence,
    ReplaceEnglishKeyword,
};
