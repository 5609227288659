import React from "react";
import i18next from "i18next";
import Dimensions from "react-native-web-dimension-helper";
import _, { result } from "lodash";
import I18n from "react-i18n";
import StoreManager from "../redux/";
import Constants from "../config/Constants";
import AppConfig from "../config/AppConfig";
import { v4 as uuid } from "uuid";
import SplitPinYin from "./pinyin-split";
import axios from 'axios';
import fileDownload from 'js-file-download';
import { EnumHasFlag, GetPlainTextFromSentence } from "./MarkTextHelper";
import SessionHelper from "./SessionHelper";
import UnitStyle from "../mobile/screens/course/units/UnitStyle";
import UIDs from "../UIDs";
import EnglishTokenCodec from "./EnglishTokenCodec";
const moment = require('moment-timezone');
const pinyin = require('pinyin');

const pinyinConvert = require("pinyin-tone-convert");

const countries = require("i18n-iso-countries");
// If you use the package in a browser environment, you have to register the languages you want to use to minimize the file size.
countries.registerLocale(require("i18n-iso-countries/langs/zh.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/th.json"));
countries.registerLocale(require("i18n-iso-countries/langs/ko.json"));
countries.registerLocale(require("i18n-iso-countries/langs/ja.json"));
countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
countries.registerLocale(require("i18n-iso-countries/langs/ru.json"));

// const pinyinSplit = require("pinyin-split");
function convertSnakeCaseKeysToCamelCase(obj) {
	//if not object or array we just return
	if (!_.isObject(obj) && !_.isArray(obj)) {
		return obj;
	}
	let camelCaseObject;
	//if object we return a object, if array we return array
	if (_.isPlainObject(obj)) {
		camelCaseObject = {};
	} else if (_.isArray(obj)) {
		camelCaseObject = [];
	}
	_.forEach(obj, function (value, key) {
		if (_.isPlainObject(value) || _.isArray(value)) {
			// checks that a value is a plain object or an array - for recursive key conversion
			value = convertSnakeCaseKeysToCamelCase(value); // recursively update keys of any values that are also objects
		}
		camelCaseObject[_.camelCase(key)] = value;
	});
	return camelCaseObject;
}

function convertCamelCaseKeysToSnakeCase(obj) {
	//if not object or array we just return
	if (!_.isObject(obj) && !_.isArray(obj)) {
		return obj;
	}
	let snakeCaseObject = {};
	//if object we return a object, if array we return array
	if (_.isPlainObject(obj)) {
		snakeCaseObject = {};
	} else if (_.isArray(obj)) {
		snakeCaseObject = [];
	}
	_.forEach(obj, function (value, key) {
		if (_.isPlainObject(value) || _.isArray(value)) {
			// checks that a value is a plain object or an array - for recursive key conversion
			value = convertCamelCaseKeysToSnakeCase(value); // recursively update keys of any values that are also objects
		}
		snakeCaseObject[_.snakeCase(key)] = value;
	});
	return snakeCaseObject;
}

function convertCamelCaseKeysToUpperSnakeCase(obj) {
	//if not object or array we just return
	if (!_.isObject(obj) && !_.isArray(obj)) {
		return obj;
	}
	let snakeCaseObject = {};
	//if object we return a object, if array we return array
	if (_.isPlainObject(obj)) {
		snakeCaseObject = {};
	} else if (_.isArray(obj)) {
		snakeCaseObject = [];
	}
	_.forEach(obj, function (value, key) {
		if (_.isPlainObject(value) || _.isArray(value)) {
			// checks that a value is a plain object or an array - for recursive key conversion
			value = convertCamelCaseKeysToUpperSnakeCase(value); // recursively update keys of any values that are also objects
		}
		snakeCaseObject[_.toUpper(_.snakeCase(key))] = value;
	});
	return snakeCaseObject;
}

// convert ban2, ban1 to pinyin
function convertToPinyin(value) {
	try {
        //handle ng4, n4 cases
        //ń、ńg、ň、ňg、ǹ、ǹg
        //ḿ m̀
        //https://www.zdic.net/hans/%E5%91%A3
        //https://www.zdic.net/hans/%E5%97%AF
        const map = {
            n2: "ń",
            n3: "ň",
            n4: "ǹ",
            ng2: "ńg",
            ng3: "ňg",
            ng4: "ǹg",
            m2: "ḿ",
            m4: "m̀",
        };

        if (_.has(map, value)) {
            return map[value];
        }

		//first convert v to ü
        value = _.replace(value, "v", "ü");
		return pinyinConvert(value);
	} catch (e) {
		return value;
	}
}
// split pinyin to array

// function splitPinyinToArray(value) {
//     var syllableArray = value.split(" "); //pinyinSplit(value, true);
//
//     return _.filter(syllableArray, function (syllable) {
//         if (isNullOrEmpty(syllable)) {
//             return false;
//         }
//
//         // need newline character
//         if (syllable.indexOf("\n") !== -1) {
//             return true;
//         }
//
//         if (!syllable.replace(/\s/g, "").length) {
//             return false;
//         }
//
//         return true;
//     });
// }

function splitPinyinToArray(value) {
	if(_.isArray(value)){
		return value;
	}
	//token input data
	if (_.isString(value) && value.indexOf(Constants.CHARS_FIELD_SYLLABLES_SEPARATOR) > -1){
		//_.trim(value, Constants.CHARS_FIELD_SYLLABLES_SEPARATOR) is for \n case for multi-row unit
		return _.split(_.trim(value, Constants.CHARS_FIELD_SYLLABLES_SEPARATOR), Constants.CHARS_FIELD_SYLLABLES_SEPARATOR);
	}
	const splitValues = SplitPinYin(value, true, true); //return everything and wrap pinyin into lists
	let result = [];

	_.each(splitValues, (item) => {
		if (_.isArray(item)) {
			//pinyin is array
			result.push(item[0]);
		} else {
			//for things that is not pinyin, we treat every char as a length.
			result = result.concat(_.toArray(item));
		}
	});

	return _.filter(result, function (syllable) {
		if (isNullOrEmpty(syllable)) {
			return false;
		}

		// need newline character
		if (syllable.indexOf("\n") !== -1) {
			return true;
		}

		return true;
	});
}

function getCurrentLocale() {
	return I18n.locale;
}

function isCNCulture() {
	return getCurrentLocale().indexOf("zh") > -1;
}

function decodeURL(url) {
	try {
		return decodeURIComponent(url);
	} catch (e) {
		return url;
	}
}

function subString(fullString, startNdx, length, addDots) {
	if (fullString.length > startNdx + length) {
		return fullString.substring(startNdx, length) + (addDots ? "..." : "");
	} else {
		return fullString.substring(startNdx);
	}
}

//match ="http://
const httpRegex1 = new RegExp('="http://', "g");
//match ='http://
const httpRegex2 = new RegExp("='http://", "g");
//if html have url like http://www.... can not be load in https env.
//so replace all to // to make it auto fill
function replaceHttpToFitHttpsInHtml(htmlStr) {
	if (!htmlStr) {
		return "";
	}

	return htmlStr.replace(httpRegex1, '="https://').replace(httpRegex2, "='https://");
}

const httpRegex3 = new RegExp("^http://", "g");
//replace http url to https url
function replaceURLToHttps(htmlStr) {
	if (!htmlStr) {
		return "";
	}

	return htmlStr.replace(httpRegex3, "https://");
}

/**
 * for use session data in some extra place
 * @return {*}
 */
function getSessionState() {
	try {
		let store = StoreManager.getStore();
		return store.getState().session;
	} catch (e) {
		//DebugLogger.log(e);
		//when app is starting we will get error here.
		//we need to try resolve this
		return {};
	}
}

//获取当前的partnerID  目前 fields/type/TextSourceField 用了
function getCurrentPartnerId() {
    let partner = SessionHelper.get("partner");
    return partner ? partner.id : "";
}

function formatToHtmlString(content) {
	return `<html><head><style>*{box-sizing: border-box;}p{margin-top: 4px;margin-bottom: 4px}</style></head><body><div style="font-size:12px;padding: 4px;">${content}</div></body></html>`;
}

function getENNumSeqSuffix(index) {
	let pNum = index;
	if (!_.isNumber(index)) {
		pNum = _.toNumber(index);
		if (pNum <= 0 || isNaN(pNum)) {
			return "";
		}
	}
	switch (pNum) {
		case 1:
			return "st";
		case 2:
			return "nd";
		case 3:
			return "rd";
		default:
			return "th";
	}
}

function generateUUID() {
	const id = uuid();
	return _.replace(id, /-/gm, "");
}

// item: b, ang2, eng, peng3
function getAudioVirtualPathFromSyllable(syllable) {
	return Constants.FILE_STORAGE.PINYIN_PATH + syllable + ".mp3";
}

function convertToFileServerPath(serverPath) {
	if (!serverPath || serverPath.length < 2) return '';
	else if (serverPath.indexOf("~/") === 0) return fileServerPath() + serverPath.substring(2);
	else return serverPath;
}

function replaceHtmlVirtualPath(html) {
    if (!html) {
        return html;
    }
    return html.split('${YIDU_FILE_SERVER}~/').join(fileServerPath());
}

const fileServerPath = () => {
	if (AppConfig.FILE_SERVER) {
        if (AppConfig.FILE_SERVER.endsWith("/")) {
            return AppConfig.FILE_SERVER;
        }
        return AppConfig.FILE_SERVER + "/";
    }
	else return AppConfig.APP_SERVICE_BASE + "/";
};

const isGuest = (memberId) => {
	return _.toLower(memberId) === _.toLower(Constants.USER_GUEST);
};

const isLoggedIn = (memberId) => {
	if (!isGuest(memberId)) {
		return !_.isEmpty(memberId);
	}
	return false;
};

const isAdmin = (user) => {
	return user && _.isNumber(user.role) && user.role & Constants.USER_ROLE.ADMIN;
};

const isOnCall = (user) => {
	return user && _.isNumber(user.role) && user.role & Constants.USER_ROLE.ON_CALL;
};

const isTeacher = (user) => {
	return user && _.isNumber(user.role) && user.role & Constants.USER_ROLE.TEACHER;
};

const isAgent = (user) => {
	return user && _.isNumber(user.role) && user.role & Constants.USER_ROLE.AGENT;
};

const isStudent = (user) => {
	return user && _.isNumber(user.role) && user.role === Constants.USER_ROLE.USER;
};

const isBookManager = (user) => {
	return user && _.isNumber(user.role) && user.role & Constants.USER_ROLE.BOOK_MANAGER;
};

const isPartner = (partner, partnerRole) => {
	return  partner && !isNullOrEmpty(partner.id);
};

const isEditorPartner = (partner, partnerRole) => {
	return partner && partner.role === partnerRole.EditorPartner;
};

const isManagerPartner = (partner, partnerRole) => {
	return partner && partner.role === partnerRole.ManagerPartner;
};

const isAssistantPartner = (partner, partnerRole) => {
	return partner && partner.role === partnerRole.AssistantPartner;
};

const isNumeric = (n) => {
	return !isNaN(parseFloat(n)) && isFinite(n);
};

const isNullOrEmpty = (s) => {
	return _.isNull(s) || _.isEmpty(s);
};

const isNullOrUndefined = (o) => {
	return _.isNull(o) || _.isUndefined(o);
};

const isCharacter = (str) => {
	if (Object.prototype.toString.call(str) !== "[object String]") {
		return false;
	}
	if (str && str.length !== 1) {
		return false;
	}
	const testCases = [
		["\u4E00", "\u9FEF"], //基本汉字&基本汉字补充
		["\u3400", "\u4DB5"], //扩展A
		["\u{20000}", "\u{2A6D6}"], //扩展B
		["\u{2A700}", "\u{2B734}"], //扩展C
		["\u{2B740}", "\u{2B81D}"], //扩展D
		["\u{2B820}", "\u{2CEA1}"], //扩展E
		["\u{2CEB0}", "\u{2EBE0}"], //扩展F
	];
	for (const t of testCases) {
		if (t[0] <= str && str <= t[1]) {
			return true;
		}
	}
	return false;
};

const randomizeArray = (arr) => {
	const length = arr.length;
	let arr1 = [];
	for (var i = 0; i < length; i++) {
		arr1[i] = i;
	} //建立数组下标数组
	let arr2 = [];
	for (let i = 0; i < length; i++) {
		arr2[i] = arr1.splice(Math.floor(Math.random() * arr1.length), 1);
	} //将数组下标随机打乱
	let arr3 = [];
	for (let i = 0; i < length; i++) {
		arr3[i] = arr[arr2[i]];
	} //将数组按打乱后的下标输出
	return arr3;
};

//
// get the localized string from localizable string object
//
function localizeString(localizableString) {
	if (!localizableString) return "";

	let lan = i18next.language ? i18next.language : "en";
	return localizableString[lan] ? localizableString[lan] : localizableString["en"];
}

function localizeStringNoChinese(localizableString) {
	if (!localizableString) return "";

	let lan = i18next.language ? i18next.language : "en";
	lan = lan === "zh" ? "en" : lan;//when cn culuture show en
	return localizableString[lan] ? localizableString[lan] : localizableString["en"];
}

function localizeStringByLanguage(localizableString, lan) {
	if (!localizableString) return "";
	return localizableString[lan] ? localizableString[lan] : localizableString["en"];
}

//
// get the translated chinese from localizable string object
//
function translateChinese(localizableString, upperCase = false, sourceLang = "") {
    if (!localizableString) return "";

    let lan = i18next.language && i18next.language !== "zh" ? i18next.language : "en";
    if (lan === sourceLang) {
        if (lan !== 'zh') {
            lan = 'zh';
        } else {
            lan = 'en';
        }
    }

    if(lan === "en" && upperCase){
        if(localizableString["en"]){
            let str = localizableString["en"];
            return str.slice(0,1).toUpperCase() +str.slice(1).toLowerCase();
        }
    }
    return localizableString[lan] ? localizableString[lan] : localizableString["en"];
}

function buildLocalizableString(lans, texts) {
	let localizableString = {};

	for (let i = 0; i < lans.length; i++) {
		localizableString[lans[i]] = texts[i];
	}

	return localizableString;
}
function isTwoWordsSame(w1, w2) {
	if (!w1.chars || !w2.chars) return false;
	return w1.chars.toString() === w2.chars.toString();
}
function combineTranslation(formData, keyName, index = -1) {
	var _return = {};
	if (index === -1) {
		if (formData[`${keyName}-lan`]) {
			for (let i = 0; i < formData[`${keyName}-lan`].length; i++) {
				if (!formData[`${keyName}-text`][i]) continue;
				_return[formData[`${keyName}-lan`][i]] = formData[`${keyName}-text`][i];
			}
		}
		return Object.keys(_return).length === 0 ? null : _return;
	} else {
		if (formData[keyName]) {
			var translation = formData[keyName][index];
			for (let i = 0; i < translation[`-lan`].length; i++) {
				if (!translation[`-text`][i]) continue;
				_return[translation[`-lan`][i]] = translation[`-text`][i];
			}
		}
		return Object.keys(_return).length === 0 ? null : _return;
	}
}

function combineTranslationForSentences(lan, text){
    var newTranslation = {}
    for(var i=0; i<lan.length;i++){
        newTranslation[lan[i]] = text[i];
    }
    return newTranslation;
}

const createArrayWithNumbers = (length) => {
	return Array.from({ length }, (_, k) => k);
};

const isChinese = (val) => {
	return /^[\u4E00-\u9FA5]+$/.test(val);
};
const isEnglish = (val) => {
	return /^[a-zA-Z0-9'\s-]+$/.test(val);
};

// ·￥。 ？ ！ ， 、 ； ： “ ” …（ ）《》『』【】—．〈〉〔〕「」‘’–
// . ? ! , ; : " ( ) - #$%&'*+/<=>@[\\]^_`{|}~
const isChineseWithPunctuation = (val) => {
	return /^[\u4E00-\u9FA5\u00b7\uffe5\u3002\uff1f\uff01\uff0c\u3001\uff1b\uff1a\u201c\u201d\uff08\uff09\u2026\u300a\u300b\u300e\u300f\u3010\u3011\u2014\uff0e\u3008\u3009\u3014\u3015\u300c\u300d\u2018\u2019\u2013\u0020-\u002f\u003a-\u0040\u005b-\u0060\u007b-\u007e\n\t\v\f\r]+$/.test(val);
};

const containSentencePunc = (val) => {
	return val && val
		.trim()
		.split('')
		.some(s => sentencePunctuations.includes(s));
};

/**
 * combine the basic fields into sentences
 * @param {string} chars
 * @param {string} syllables
 * @param {{zh:string,en:string,th:string}} translation
 * @returns {sentences[]} sentences
 */
function combineFieldsIntoSentences(chars, syllables, translation, separator = "\n") {
	chars = chars
		.trim()
		.split(separator)
		.filter((s) => s && s.trim())
		.map((char) => char.split(""));
	syllables = syllables
		.trim()
		.split(separator)
		.filter((s) => s && s.trim())
		.map((syllable) => splitPinyinToArray(syllable));

	// chars length should be equal to syllables length
	if ((syllables.length > 0 && chars.length !== syllables.length) || chars.length === 0) return [];

	let translations = translation;
	for (var key in translation) {
		if (isNullOrEmpty(translation[key])) {
			translations[key] = [];
			continue;
		}
		translations[key] = translation[key].trim().split((separator===" "?"  ": separator));
		if (translations[key].length !== chars.length) return [];
	}

	let sentences = [];
	for (let i = 0; i < chars.length; i++) {
		let _translation = {};
		for (let key in translations) {
			if (!translations[key].length) {
				continue;
			}
			_translation[key] = translations[key][i];
		}
		sentences.push({
			chars: chars[i],
			syllables: syllables[i] || [],
			translation: _translation,
		});
	}
	return sentences;
}
/**
 * extral fields from sentences.
 * combine multi line chars ,syllables etc.
 * @param {sentence[]} sentences
 * @param {string} prefix the prefix of return object
 */
function extractFieldsFromSentences(sentences, prefix = "", separator = "\n") {
	let _chars = [],
		_syllables = [],
		_translation = {};
	sentences.forEach((sentence, index) => {
		const { chars, syllables, translation } = sentence;
		if (chars) _chars.push(chars.join(""));
		//if (syllables) _syllables.push(syllables.join(""));
		if (syllables) _syllables.push(syllables.join(Constants.CHARS_FIELD_SYLLABLES_SEPARATOR));
		if (translation)
			for (let key in translation) {
				if (isNullOrEmpty(translation[key])) translation[key] = "";
				if (!_translation[key]) _translation[key] = "";
				_translation[key] += (separator===" "?"  ": separator) + translation[key];
				if (index === sentences.length - 1)
					_translation[key] = _translation[key].trim();
			}
	});
	_chars = _chars.join(separator);
	_syllables = _syllables.join(separator);

	if (!isNullOrEmpty(prefix)) {
		return {
			[`${prefix}Chars`]: _chars,
			[`${prefix}Syllables`]: _syllables,
			[`${prefix}Translation`]: _translation,
		};
	} else {
		return {
			chars: _chars,
			syllables: _syllables,
			translation: _translation,
		};
	}
}

function extractFieldsFromParagraphs(paragraphs, prefix = "", separator = "\n") {
	let _chars = [],
		_syllables = [],
		_translation = {};
	paragraphs.forEach(({sentences}, index) => {
		let { chars, syllables, translation } = extractFieldsFromSentences(sentences, prefix, "");
		if (chars) _chars.push(chars);
		if (syllables) _syllables.push(syllables);
		if (translation)
			for (let key in translation) {
				if (isNullOrEmpty(translation[key]))  translation[key] = "";
				if (!_translation[key]) _translation[key] = "";
				_translation[key] += (separator===" "?"  ": separator) + translation[key];
				if (index === paragraphs.length - 1)
					_translation[key] = _translation[key].trim();
			}
	});
	_chars = _chars.join(separator);
	_syllables = _syllables.join(separator);

	if (!isNullOrEmpty(prefix)) {
		return {
			[`${prefix}Chars`]: _chars,
			[`${prefix}Syllables`]: _syllables,
			[`${prefix}Translation`]: _translation,
		};
	} else {
		return {
			chars: _chars,
			syllables: _syllables,
			translation: _translation,
		};
	}
}

/**
 * map sentence syllables into words.
 * @param {[]} sentences
 */
function mapSentenceSyllablesIntoWords(sentences) {
	sentences.forEach((sentence,sIdx,sArr) => {
		if (sentence.syllables && sentence.words) {
			let syllables = Array.from(sentence.syllables)||[];
			sArr[sIdx].words.forEach((word,wIdx,wArr) => {
				if (word.syllables) {
					wArr[wIdx].syllables.forEach((letter,lIdx,lArr) => {
						if (syllables.length === 0) return;
						lArr[lIdx] = (syllables.splice(0,1))[0];
					})
				}
			})
		}
	});
}

const isPunctuation = val => {
    return "·￥。？！，、；：“”…（）《》『』【】—．〈〉〔〕「」‘’–.?!,;:\"()-#$%&'*+/<=>@[\\]^_`{|}~ ".indexOf(val) > -1;
};

/**
 * 只比较中文和字母数字
 * @param char
 * @returns {boolean}
 */
const IsNoNeedCompareChar = char => {
	const regex = /[A-Za-z0-9]/;
	if (regex.test(char)) {
		return false;
	}

	if (isPunctuation(char)){
		return true;
	}
	return !isChineseCharacters(char);
};

const containSpecialPunctuations = val => {
	const specialPunctions = ".?()$*+[\]^_|";
	return val && val.split("").findIndex(e=>specialPunctions.indexOf(e)>-1)>-1;
};

const sentencePunctuations = [ "!", "?", ";" , "。", "！", "？", "；", "…" ];

function splitParagraph(arr) {
	let newArr = [];
	while (arr.length > 0) {
		for (let j = 0; j < arr.length; j++) {
			let isSenPunc = sentencePunctuations.indexOf(arr[j])>-1;
			if (isSenPunc || j===(arr.length-1)) {
				if (j===(arr.length-1)) {
					let tailArr = arr.splice(0, j+1).join("").trim().split("");
				    if (newArr.length === 0 || tailArr.findIndex(e=>!isPunctuation(e)) > -1)
						newArr.push(tailArr);
					else
						newArr[newArr.length-1] = newArr[newArr.length-1].concat(tailArr); //如果剩余字符都是标点，则合并到上一句中
					break;
				}

				let k = j+1;
				while (k < arr.length) {
					isSenPunc = sentencePunctuations.indexOf(arr[k])>-1;
					if (!isSenPunc) break;
					k++;
				}

				//如果句子标点后紧跟引号或空格，则索引加一
				while(arr.length>k && "”\" ".indexOf(arr[k])>-1)
					k = k+1;

				newArr.push(arr.splice(0, k).join("").trim().split(""));

				break;
			}
		}
	}
	return newArr;
}

function combineSentence(arr, shouldCombineCallback) {
	if (!arr || !_.isArray(arr) || !shouldCombineCallback) return;
	for (let i = 0; i < arr.length; i++) {
		//小于等于5个字的句子向后合并
		while (i<(arr.length-1) && shouldCombineCallback(arr[i])) {
			if(_.isArray(arr[i])) {
				arr[i+1] = arr[i].concat(arr[i+1]);
			} else {
				arr[i+1] = (arr[i]+arr[i+1]);
			}
			arr.splice(i, 1);
		}
		//最后一句小于等于5个字的句子向前合并
		if (i===(arr.length-1) && i>0 && shouldCombineCallback(arr[i])) {
			if(_.isArray(arr[i])) {
				arr[i-1] = arr[i-1].concat(arr[i]);
			} else {
				arr[i-1] = (arr[i-1]+arr[i]);
			}
			arr.splice(i, 1);
		}
	}
}

function combineFieldsIntoParagraphs(chars) {
	let _sentences = combineFieldsIntoSentences(chars, "", {}, "\n");

	let paragraphs = [];
	for (let i = 0; i < _sentences.length; i++) {
		let sentence = _sentences[i];
		let sentences = [];

		let chars = splitParagraph(sentence.chars);

		combineSentence(chars, itemArray => {
			let plainText = GetPlainTextFromSentence(itemArray.join(""));
			let wordCount = getWords(plainText).join("").length;
			return wordCount <= 10;
		});

		if (chars.length === 0) return [];

		for (let i = 0; i < chars.length; i++) {
			sentences.push({
				chars: chars[i],
				syllables: [],
				translation: {},
			});
		}
		paragraphs.push({
			sentences: sentences
		});
	}
	return paragraphs;
}

function combineFieldsIntoDialog(chars, splitMinLength = 60) {
	let dialog = {};
	let sentences = [];

	if (chars.length > splitMinLength) {
		sentences = combineFieldsIntoParagraphs(chars).flatMap(f => f.sentences);
	} else {
		sentences = combineFieldsIntoSentences(chars, "", {}, "\n");
	}

	dialog = { sentences };
	return dialog;
}

/**
 * extract data from Content Field
 * 从表单数据中提取各种Field（CharsField，ImageField，AudioField）可用的数据结构
 * @param {{}} formData
 * @param {number} singleUnitIndex
 */
const extractFieldsFromContentFieldFormData = (formData, singleUnitIndex) => {
    let singleUnitPattern = new RegExp(`content.*\\[${singleUnitIndex}\\]article\\[(\\d*)\\]([A-Za-z]*)?`);
    let pattern = /content.*?article\[(\d*)\]([A-Za-z]*)?/;
    let tempFieldPayloads = [];
    for (let key in formData) {
        if ((singleUnitIndex || singleUnitIndex === 0) && !singleUnitPattern.test(key)) {
            continue;
        }
        if (pattern.test(key)) {
            let matches = pattern.exec(key);
            let fieldIndex = parseInt(matches[1]);
            let componentName = matches[2];
            if (componentName === "type") continue;
            let fieldName = formData[key.replace(new RegExp(`${componentName}.*`), "type")];

            if (fieldName === "CharsField" && componentName === "translation") {
                // translation
                // Helper.combineTranslation(formData, key.replace(`[${fieldIndex}`, ""));
                let _translation = {};
                for (let i = 0; i < 3; i++) {
                    let lan = formData[key.replace(/translation.*/, `translation-lan[${i}]`)];
                    let text = formData[key.replace(/translation.*/, `translation-text[${i}]`)];
                    _translation[lan] = text;
                }
                tempFieldPayloads[fieldIndex] = {
                    ...tempFieldPayloads[fieldIndex],
                    [componentName]: {
                        value: _translation,
                        name: key.replace(/translation.*/, "translation"),
                    },
                    type: fieldName,
                };
            } else {
                tempFieldPayloads[fieldIndex] = {
                    ...tempFieldPayloads[fieldIndex],
                    [componentName]: {
                        value: formData[key],
                        name: key,
                    },
                    type: fieldName,
                };
            }
        }
    }
    return tempFieldPayloads;
}
/**
 * 将表单数据打包成服务器的数据结构类型
 * @param {{}} formData
 * @param {number} singleUnitIndex
 */
const combineContentField = (formData, singleUnitIndex) => {
    let fieldPayloads = extractFieldsFromContentFieldFormData(formData, singleUnitIndex);
    let result = [];
    for (let i = 0; i < fieldPayloads.length; i++) {
		const fieldPayload = fieldPayloads[i];
		if(!fieldPayload) continue;
        const type = Constants.FIELD_TYPE[fieldPayload.type.toUpperCase()];
        let _fieldPayload = {};
        switch (type) {
            case Constants.FIELD_TYPE.CHARSFIELD:
                let sentences = combineFieldsIntoSentences(fieldPayload.chars.value, fieldPayload.syllables.value, fieldPayload.translation.value);
                _fieldPayload = { sentences: sentences, audioPath: fieldPayload.audioPath.value };
                break;
            case Constants.FIELD_TYPE.IMAGEFIELD:
                _fieldPayload = { imagePath: fieldPayload.imagePath.value };
                break;
            case Constants.FIELD_TYPE.AUDIOFIELD:
                _fieldPayload = { audioPath: fieldPayload.audioPath.value };
                break;
            default:
                break;
        }
        result.push({
            fieldPayload: JSON.stringify(_fieldPayload),
            type: type
        });
    }
    return { children: result };
}
/**
 * 处理从服务器传来的ContentField的数据结构
 * @param {[]} children
 * @param {number} singleUnitIndex The index of name used to construct the form input
 */
const extractFieldsFromContentFieldPayloads = (children = [], singleUnitIndex) => {
    let result = [];
    for (let i = 0; i < children.length; i++) {
        const child = children[i];
        let fieldPayload = JSON.parse(child.fieldPayload);
        let type = Constants.FIELD_TYPE_MAP_ARRAY[child.type];
        let _fieldPayload = {};
        let prefix = `content${singleUnitIndex || singleUnitIndex === 0 ? `[${singleUnitIndex}]` : ""}article[${i}]`
        for (let key in fieldPayload) {
            if (key === "sentences" && !isNullOrEmpty(fieldPayload[key])) {
                const { chars, syllables, translation } = extractFieldsFromSentences(fieldPayload[key]);
                _fieldPayload = {
                    ..._fieldPayload,
                    chars: {
                        name: `${prefix}chars`,
                        value: chars,
                    },
                    syllables: {
                        name: `${prefix}syllables`,
                        value: syllables,
                    },
                    translation: {
                        name: `${prefix}translation`,
                        value: translation,
                    },
                };
            } else {
                _fieldPayload = {
                    ..._fieldPayload,
                    [key]: {
                        name: `${prefix}${key}`,
                        value: fieldPayload[key],
                    },
                };
            }
        }
        _fieldPayload.fieldName = type;
        result.push(_fieldPayload);
    }
    return result;
};

/**
 * split into words with punctuations
 * ·￥。？！，、；：“”（）…《》『』【】—．〈〉〔〕「」‘’–
 * . ? ! , ; : " ( ) - #$%&'*+/<=>@[\\]^_`{|}~
 * @param {string} val
 * @return {string[]}
 */
const getWords = (val) => {
	return val.split(/[\u00b7\uffe5\u3002\uff1f\uff01\uff0c\u3001\uff1b\uff1a\u201c\u201d\uff08\uff09\u2026\u300a\u300b\u300e\u300f\u3010\u3011\u2014\uff0e\u3008\u3009\u3014\u3015\u300c\u300d\u2018\u2019\u2013\u0020-\u002f\u003a-\u0040\u005b-\u0060\u007b-\u007e\n\t\v\f\r]/g).filter((s) => s);
};

/**
 * check if the type is mp3
 * @param {string} type
 * @return {boolean}
 */
const isMp3 = (type) => {
	return Constants.VALID_MP3_MIMES.includes(type);
};

const getLocale = (lngCode) => {

    for(var i = 0; i < Constants.SUPPORTED_LANGUAGES.length; i++){
        if(Constants.SUPPORTED_LANGUAGES[i].code === lngCode){
            return Constants.SUPPORTED_LANGUAGES[i].culture;
        }
    }

	return "en-US";
};

function countryCompareZH(a, b) {
    return pinyin.compare(a, b);
}

const getCountries = () => {
	let allCountries = countries.getNames(i18next.language, { select: "official" });
	allCountries = _.omit(allCountries, ['AX']);//AIC-9-450 暂时去掉Åland Islands的选项

	let allOrderedCountries;
	// sort by alphabet
	if (i18next.language === 'zh') {
		// 中文按拼音排序
		allOrderedCountries = Object.values(allCountries).sort(countryCompareZH);
	} else {
		// 其它语言按字母排序
		allOrderedCountries = Object.values(allCountries).sort();
	}

    //由于排序之后，没有了国家代码的缩写，重新根据序排后的值，在设置一下缩写
	let newOrderedCountries = {};
	allOrderedCountries.forEach((item, index) => {
        let countryCode = countries.getAlpha2Code(item, i18next.language)
        newOrderedCountries[countryCode] = item;
    });

	return newOrderedCountries;
    //return allOrderedCountries;
};

const getCountryName = (countryCode) =>{
	return countries.getName(countryCode, i18next.language, {select: "official"});
};

const downloadFile = (url, fileName) => {
	axios.get(url, {
		responseType: 'blob',
	})
	.then((res) => {
		fileDownload(res.data, fileName)
	});
};

const getLastFileName = (path) => {
    return path ? path.substring(path.lastIndexOf("\/")+1, path.length) : "";
}

function numberWithCommas(x) {
    return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Convert UTC To Local Time
 *
 * @param {*} date
 * @return {*}
 */
const convertToLocalTime = (date) => {
	if (!date) return date;

	const timeZone = moment.tz.guess(true);
	const localDate = moment.utc(moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS")).tz(timeZone).toDate();
	return new Date(localDate);
};

//转成本地时间 进行格式化
const convertToLocalString = (date) => {
	const localDate = convertToLocalTime(date);
	return moment(localDate).format("YYYY-MM-DD HH:mm:ss");
};

/**
 * Convert Local Time To UTC
 * @param {*} date
 * @returns
 */
const convertToUtcTime = (date) => {
	if (!date) return date;

	const y =  date.getUTCFullYear();
	const m = date.getUTCMonth();
	const d = date.getUTCDate();
	const h= date.getUTCHours();
	const M = date.getUTCMinutes();
	const s = date.getUTCSeconds();
	const utcDate = new Date(y, m, d, h, M, s);

	return utcDate;
};

const formatToUtcTime = (dateString) => {
	// const pattern = /^(\d{4}).(\d{1,2}).(\d{1,2}).? (\d{1,2}).(\d{1,2}).?(\d{1,2})?.?$/;
	// if (!pattern.test(dateString)) {
	// 	return dateString;
	// }

	// const formatDate = new Date(dateString.replace(pattern, '$1/$2/$3 $4:$5:$6'));
	if(!moment(dateString).isValid()) return dateString;
	return moment.utc(dateString).format("YYYY/MM/DD HH:mm:ss");
}

const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
}

//
// beijing: 8 (UTC +8), seattle: -8 (UTC -8)
//
function getTimeZone() {
    return 0 - new Date().getTimezoneOffset()/60;
};

function isNumberWithPunctuations(text) {
	let ret = getWords(text).every(item => isNumeric(Number(item)));
	return ret;
};

function splitByDigit(text, d, seperator = "") {
	if (!text || !isNumeric(d) || d === 0) return text;

	let str = "";
	for (let i = 1; i <= text.length; i++) {
		if (i % d === 1 && parseInt(i / d) === parseInt(text.length / d)) {
			str += text.substr(i - 1);
			break;
		}
		if (i % d === 0) {
			str += text.substr((parseInt(i / d) - 1) * d, d) + seperator;
		}
	}
	return str.trim();
}

function displayPhone(phone) {
	let codes = phone ? phone.split("-") : [];
	let phoneNumber = codes.length === 3 ? `${codes[0]}-${codes[2]}` : phone;
	return phoneNumber;
}

function buildFilePathInUnits(serverPath, useLocalFilePath) {
    // if (useLocalFilePath) {
    //     let localPath = "";
    //     if (!serverPath || serverPath.length < 2) {
    //         localPath = DocumentDirectoryPath + Constants.LOCAL_FILE_STORAGE.ROOT_PATH;
    //     } else if (serverPath.indexOf("~/") === 0) {
    //         localPath = DocumentDirectoryPath + Constants.LOCAL_FILE_STORAGE.ROOT_PATH + serverPath.replace(Constants.FILE_STORAGE.ROOT_PATH, "/");
    //     } else {
    //         localPath = serverPath;
    //     }
    //     return `file://${localPath}`;
    // } else {
        if (!serverPath || serverPath.length < 2) {
            return fileServerPath();
        } else if (serverPath.indexOf("~/") === 0) {
            return fileServerPath() + serverPath.substring(2);
        } else {
            return serverPath;
        }
    // }
}

function stripDeletedEmail(email) {
    if (email.startsWith('_DELETED_')) {
        let pos = email.indexOf('_', 9);
        if (pos >= 0) {
            return email.substring(pos + 1);
        }
    }
    return email;
}

function getLanguageCodes() {
	let codes = [];

	for(var i = 0; i < Constants.SUPPORTED_LANGUAGES.length; i++){
		codes.push(Constants.SUPPORTED_LANGUAGES[i].code);
    }

	return codes;
}

function existsSepcialContent(val){
    var nameReg = /[^\a-\z\A-\Z0-9\u4E00-\u9FA5\.\,\?\ \_\<\>\。\，\[\]\*\&\^\%\#\@\$\￥\……\(\)\、\-\——\=\;\@\！\!\+\$]/g;
    return nameReg.test(val);
}

function getImageWidthHeightWithPath(imgWidth, imgHeight, maxImgWidth)
{
    let scale = 1;
    if(imgWidth > maxImgWidth){
        scale = maxImgWidth / imgWidth;
        imgWidth = parseInt(imgWidth * scale);
    }

    imgHeight = parseInt(imgHeight * scale);

    return {imgWidth, imgHeight}
}

function convertToAbsoluteUrl(serverPath) {
    // if (!serverPath || serverPath.length < 2) {
    //     return DocumentDirectoryPath;
    // } else if (serverPath.indexOf("~/") === 0) {
    //     return DocumentDirectoryPath + serverPath.substring(1);
    // } else {
    //     return serverPath;
    // }
    if(serverPath){ // webApp查看历史作业时，打开页面播放器的默认文件是空的
        serverPath = serverPath.replace("ü", "v");
        if (!serverPath || serverPath.length < 2) {
            return fileServerPath();
        } else if (serverPath.indexOf("~/") === 0) {
            return fileServerPath() + serverPath.substring(2);
        } else {
            return serverPath;
        }
    }
    return serverPath;
}

function buildWordAudioName(wordSyllables) {
    if (wordSyllables && !_.isEmpty(wordSyllables) && _.isArray(wordSyllables) && wordSyllables.length > 0) {
        let filteredSyllables = wordSyllables.filter(function (s) {
            return !isNullOrEmpty(s); // 由于儿化音的原因，er会与前面拼音连在一起，因此会出现空拼音的情况
        });
        return filteredSyllables.length > 0 ? filteredSyllables.join("-") : "";
    }

    return "";
}

function hasChineseChars(val) {
    const regExp = /.*[\u4e00-\u9fa5]+.*$/;
    return regExp.test(val);
};

function getUnicodeOfChineseChar(chars, separator) {
    let result = "";
    chars = _.isArray(chars) ? chars.join("") : chars.toString();
    for (let i = 0; i < chars.length; i++) {
        const ichar = chars.charCodeAt(i);
        if (ichar > 32 && ichar < 127) {
            result += chars[i].toString();
        } else {
            const hex = ("0000" + ichar.toString(16)).slice(-4);
            result += hex + separator;
        }
    }
    result = result.substr(0, result.length - separator.length);
    return result.toUpperCase();
}

const getAvatarStr= (userName) => {
    let avatarStr = "";
    if(userName){
        if(userName.indexOf(' ')>0){
            let nameArray = _.split(userName, ' ', 2);
            avatarStr = nameArray[0].substr(0,1) + nameArray[1].substr(0,1);
        } else{
            avatarStr=userName.substr(0,1);
        }

    }
    return avatarStr.toUpperCase();
};

// e.g., [[],[],...]
const createTwoDimensionArray = length => {
    return Array(length).fill(null).map(() => []);
};

/**
 * get the number order string
 *
 * @param {int} i
 * @return {string}
 */
const getNumberStr= (i) => {
    let lan = i18next.language ? i18next.language : "en";
    if (!isNumeric(i) || i < 1) return "";
    if(lan !== "en") return i;

    switch(i) {
        case 1: return "1st";
        case 2: return "2nd";
        case 3: return "3rd";
        default: return i + "th";
    }
};

const parseSpeechTestResultForWordComponent = (result, syllables) => {
    if (result && result.length > 0) {
        let letterHelperItem = [];
        _.each(result, (item, index) => {
            //match the index from words...
            let charHelperItem = { char: item.word, pinyin: syllables[index], colorKind: UnitStyle.blockAnswerWrongFont }; //其它的红色
            if (item.errorType.toLowerCase() === "omission") {
                charHelperItem.colorKind = UnitStyle.itemDefColor; //跳过的原色
            } else if (item.errorType.toLowerCase() === "none") {
                charHelperItem.colorKind = UnitStyle.blockAnswerRightFont; //正确的绿色
            }
            charHelperItem.accuracyScore = item.accuracyScore;
            charHelperItem.nextInsertedWord = item.nextInsertedWord;
            charHelperItem.previousInsertedWord = item.previousInsertedWord;

            letterHelperItem.push(charHelperItem);
        });
        return letterHelperItem;
    }
    return [];
};

const parseSpeechTestResultForSentenceComponent = (words, result) => {
    //build the override word array, we need match the letter char and the index.
    let currentIndex = 0;
    let overrideWordArray = [];
    let overrideLetterHelperArray = [];
    _.each(words, (item, i) => {
        let wordItem = _.cloneDeep(item);
        let letterHelperItem = [];
        _.each(item.chars, (charItem, charIndex) => {
            //match the index from words...
            let charHelperItem = { char: charItem, colorKind: UnitStyle.blockAnswerWrongFont }; //其它的红色
            if (result[currentIndex].errorType.toLowerCase() === "omission") {
                charHelperItem.colorKind = UnitStyle.itemDefColor; //跳过的原色
            } else if (result[currentIndex].errorType.toLowerCase() === "none") {
                charHelperItem.colorKind = UnitStyle.blockAnswerRightFont; //正确的绿色
            }
            charHelperItem.accuracyScore = result[currentIndex].accuracyScore;
            charHelperItem.nextInsertedWord = item.nextInsertedWord;
            charHelperItem.previousInsertedWord = item.previousInsertedWord;
            letterHelperItem.push(charHelperItem);

            currentIndex += 1;
        });

        overrideWordArray.push(wordItem);
        overrideLetterHelperArray.push(letterHelperItem);
    });

    return { overrideWordArray: overrideWordArray, overrideLetterHelperArray: overrideLetterHelperArray };
};

/**
 * check if phrases are in the answer
 *
 * @param {[{}]|string} phrases
 * @param {string} answer
 * @param {function} failCallback
 * @returns {boolean}
 */
const phrasesInAnswer = (phrases, answer, failCallback) => {
    let newPhrases = _.cloneDeep(phrases);
    let newAnswer = _.clone(answer);

    newPhrases = _.isString(newPhrases) ? [newPhrases] : newPhrases;
    for (let i = 0; i < newPhrases.length; i++) {
        let phrase = _.isString(newPhrases[i]) ? newPhrases[i] : _.join(newPhrases[i].chars, "");
        let words = getWords(phrase);
        for (let j = 0; j < words.length; j++) {
            if (newAnswer.indexOf(words[j]) === -1) {
                if (failCallback)
                    failCallback(phrase);
                return false;
            } else {
                newAnswer = newAnswer.replace(words[j], "");
            }
        }
    }
    return true;
};

function buildPathSegments(str) {
    if (isNullOrEmpty(str)) {
        return ['000', '000'];
    }

    var chunks = str.match(/.{1,3}/g);
    if (chunks.length < 2) {
        chunks = ['000', '000'];
    }

    return chunks;
}

const isSubjective = unitType => {
        return unitType && unitType.startsWith("PS");
};

const removeSepcialUnicode = val => {
    if (_.isObjectLike(val)) {
        let returnObj = _.isArray(val) ? [] : {};
        _.forIn(val, (v, k) => {
            returnObj[k] = removeSepcialUnicode(v);
        });
        return returnObj;
    } else if (_.isString(val)) {
	    // eslint-disable-next-line no-control-regex
        return val.replace(/[\u0000-\u0009|\u000B-\u000C|\u000E-\u001F|\u007F-\u00A0]/gi, "");  //跳过换行\u000a  \u000d
        //return val.replace(/[\u0000-\u001F|\u007F-\u00A0]/gi, "");
    } else {
        return val;
    }
};

function getWeekDays(daysInWeek) {
    let selectedDays = [];
    let numPerWeek = 0;
    for (let index = 0; index < 7; index++) {
        if (daysInWeek & 2 ** index) {
            selectedDays.push(index);
            numPerWeek += 1;
        }
    }
    return { selectedDays, numPerWeek };
}

//手机端页面设置的大小，宽度要随着mobile/css/mobile.scss中mobileRoot的宽度改变
function getContainerSize(){
    return {
        width: 960,
        height : document.documentElement.clientHeight < 600 ? 600 : document.documentElement.clientHeight, //window.innerHeight
    }
}

function clearCache(successCallback, failCallback) {
	if (successCallback) {
		successCallback();
	}
}

function isChineseCharacters(s) {
    return /[\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF]/.test(s);
}

function formatTimeDuration(secs) {
    let hour = parseInt(secs / 3600);
    secs -= hour * 3600;
    let minute = parseInt(secs / 60);
    secs -= minute * 60;
    var stayTime = null;
    return ("0" + hour).slice(-2) + ":" + ("0" + minute).slice(-2) + ":" + ("0" + secs).slice(-2);
}

const getRealUnitType = unitType => {
    const regex = /(\w)S(\w\w\d+)-O/;
    return unitType.replace(regex, "$1O$2").split('_')[0];
};

const convertAnswerToJson = answer => {
    //重写tojson,来适配 答案是 array 但是也要带上uploadedfiles的情况
    if (Array.isArray(answer) && _.has(answer, "uploadedFiles")) {
        return JSON.stringify({
            type: "aic_arrayanswer",
            array: answer,
            uploadedFiles: answer.uploadedFiles,
        });
    } else {
        return JSON.stringify(answer);
    }
};

const parseAnswerJson = answer => {
    //parse answer json,来适配 答案是 array 但是也要带上uploadedfiles的情况
    const parsedObj = _.isObject(answer) ? answer : JSON.parse(answer);

    if (_.isObject(parsedObj) && _.has(parsedObj, "type") && parsedObj.type === "aic_arrayanswer" && _.has(parsedObj, "array") && _.has(parsedObj, "uploadedFiles")) {
        const targetObjet = _.clone(parsedObj.array);
        targetObjet.uploadedFiles = parsedObj.uploadedFiles;
        return targetObjet;
    } else {
        return parsedObj;
    }
};

const isImageAnswerType = answerType => {
	return EnumHasFlag(answerType, Constants.AnswerTypeEnum.AnswerType_UploadImage);
};

const isRecordAnswerType = answerType => {
	return EnumHasFlag(answerType, Constants.AnswerTypeEnum.AnswerType_Audio);
};

const isNotDefaultAnswerType = answerType => {
	return isImageAnswerType(answerType) || isRecordAnswerType(answerType);
};

const extractAllValuesIntoArray = obj => {
    const tmpList = [];
    _.each(obj, function (value, key) {
        if (_.isString(value)) {
            tmpList.push(value);
        } else {
            tmpList.push(...extractAllValuesIntoArray(value));
        }
    });
    return tmpList;
};

const getI18nNSWithAllUnitView = uid => {
	//提前load所有unit的i18n namespace, 防止在添加单元时候的闪屏效果
	const unitUIDObject = UIDs.components.ui; //unit preview
	const unitFieldUIDObject = UIDs.screens.admin.unit; //unit fields
	const unitUIDObjectNSList = extractAllValuesIntoArray(unitUIDObject);
	const unitFieldUIDObjectNSList = extractAllValuesIntoArray(unitFieldUIDObject);
	return [uid, ...unitUIDObjectNSList, ...unitFieldUIDObjectNSList];
};

const convertNumToCircleNum = (num) => {
    /*
    //http://xahlee.info/comp/unicode_circled_numbers.html
    if (num === 0) {
        return "\u24EA";
    }
    if (0 < num < 21) {
        const startHex = 0x2460;
        return String.fromCodePoint(startHex + num - 1);
    }
    if (20 < num < 51) {
        const startHex = 0x3251;
        return String.fromCodePoint(startHex + num - 21);
    }

    return num;
    */
    return '(' + num + ')';
};

function findContentPos(html) {
    for (let i = 0; i < html.length; ++i) {
        if (html[i] === '>') {
            return i + 1;
        }
    }
    return 0;
}

function insertHtmlContentPrefix(html, prefix) {
    if (prefix) {
        let pos = findContentPos(html);
        if (pos >= 0) {
            return html.substring(0, pos) + prefix + html.substring(pos);
        }
    }
    return html;
}

/**
 * 计算排列组合
 * https://github.com/SeregPie/lodash.permutations/blob/master/src/module.js
 * @param collection
 * @param n
 * @returns {[[]]|*[]}
 */
function permutations(collection, n) {
	let array = _.values(collection);
	if (array.length < n) {
		return [];
	}
	let recur = ((array, n) => {
		if (--n < 0) {
			return [[]];
		}
		let permutations = [];
		array.forEach((value, index, array) => {
			array = array.slice();
			array.splice(index, 1);
			recur(array, n).forEach(permutation => {
				permutation.unshift(value);
				permutations.push(permutation);
			});
		});
		return permutations;
	});
	return recur(array, n);
}

/**
 * 计算所有排列组合情况
 * @param collection
 * @returns {*[]}
 */
function permutationsAll(collection) {
	let array = _.values(collection);
	let returnArray = [];
	for (let index = 0; index < array.length; index++) {
		returnArray = returnArray.concat(permutations(collection, index + 1));
	}

	return returnArray;
}

function hideInitPageLoadingIcon() {
	const onPageLoad = () => {
		const fadeTarget = document.getElementById("loadingScreen");
		fadeTarget.style.transition = "0.8s";
		fadeTarget.style.display = "none";
	};

	// Check if the page has already loaded
	if (document.readyState === "complete") {
		onPageLoad();
	} else {
		window.addEventListener("load", onPageLoad);
		// Remove the event listener when component unmounts
		return () => window.removeEventListener("load", onPageLoad);
	}
}

function getHtmlInnerTextLength(html) {
    if (!html) {
        return 0;
    }

    let parser = new DOMParser();
    let doc = parser.parseFromString(html, "text/html");
    if (!doc) {
        return 0;
    }

    if (!doc.body) {
        return 0;
    }

    if (!doc.body.innerText) {
        return 0;
    }

    return doc.body.innerText.length;
}

function isHtmlContentEmpty(html) {
    if (!html) {
        return true;
    }

    let parser = new DOMParser();
    let doc = parser.parseFromString(html, "text/html");
    if (!doc) {
        return true;
    }

    if (!doc.body) {
        return true;
    }

    if (doc.body.getElementsByTagName("img").length > 0) {
        return false;
    }

    if (doc.body.getElementsByTagName("audio").length > 0) {
        return false;
    }

    if (doc.body.getElementsByTagName("video").length > 0) {
        return false;
    }

    if (doc.body.innerText && doc.body.innerText.length > 0) {
        return false;
    }

    return true;
}

function trimTailZero(num) {
    const s = String(num);
    const ss = s.split('.');
    if (ss[1]) {
        let count = 0;
        for (let i = ss[1].length - 1; i >= 0; --i) {
            if (ss[1][i] === '0') {
                count += 1;
            } else {
                break;
            }
        }

        if (count > 0) {
            if (count === ss[1].length) {
                return ss[0];
            }
            return ss[0] + '.' + ss[1].substring(0, ss[1].length - count);
        }
    }
    return s;
}

//老师批阅对话，朗读单元时用到的 获取用户所读内容解析结果的字数
function getUserReadingAnswerTotalChars(answer) {
    if (answer && answer.chars) {
        if (!hasChineseChars(answer.chars)) {
            // 英语返回单词数量
            let codec = new EnglishTokenCodec();
            let chars = codec.encode(answer.chars);
            return _.join(_.filter(chars, s=>!IsNoNeedCompareChar(s)), "").length;
        }
    }

    let totalChars = answer && answer.chars ? _.join(_.filter(answer.chars, s=>!IsNoNeedCompareChar(s)), "").length : 0; //一句话总字数（不包括标点符号）
    return totalChars;
}

//更改词性的显示字母
function getNewAttribute(attribute) {
    let letter = attribute.substring(0,1);
    let newAttribute = letter;
    switch(letter){
        case "a":   //形容词
            newAttribute = "adj";
            break;
        case "c":   //连词
            newAttribute = "conj";
            break;
        case "d":   //副词
            newAttribute = "adv";
            break;
        case "e":   //叹词
            newAttribute = "interj";
            break;
        case "m":   //数词
            newAttribute = "num";
            break;
        case "p":   //介词
            newAttribute = "prep";
            break;
        case "r":   //代词
            newAttribute = "pron";
            break;
        case "v":   //动词
            if(attribute.length > 1){
                if(attribute.substring(0,2) === "vi"){
                    //不及物动词
                    newAttribute = "vi";
                }
            }
            break;
        default:
            newAttribute = letter;
            break;
    }
    return newAttribute;
}

/*
  renderTemplate("hello {{aa}} {{bb}} world {{bb}} {{aa}}",
  {
  "{{aa}}": (key) => (<span key={key} style={{color: 'red'}}>AA</span>),
  "{{bb}}": (key) => (<span key={key} style={{color: 'green'}}>BB</span>),
  })
*/
function renderTemplate(s, args, opts = {}) {
  let result = [s];
  let index = 0;
  for (let k in args) {
    for (let i = 0; i < result.length; ++i) {
      let r = result[i];
      if (_.isString(r)) {
        let ss = r.split(k);
        let rr = [];
        for (let j = 0; j < ss.length; ++j) {
          if (j > 0) {
            index += 1;
            let node = args[k](index);
            rr.push(node);
          }
          rr.push(ss[j]);
        }
        result[i] = rr;
      }
    }

    let temp = result;
    result = [];
    for (let i = 0; i < temp.length; ++i) {
      if (_.isArray(temp[i])) {
        result = result.concat(temp[i]);
      } else {
        result.push(temp[i]);
      }
    }
  }

  let out = [];
  for (let i = 0; i < result.length; ++i) {
    if (result[i]) {
      if (_.isString(result[i])) {
          index += 1;
          if (opts.textRender) {
              out.push(opts.textRender(index, result[i]));
          } else {
              out.push((<span key={index} style={opts.textStyle}>{result[i]}</span>));
          }
      } else {
        out.push(result[i]);
      }
    }
  }
  return out;
}

export {
	convertSnakeCaseKeysToCamelCase,
	convertCamelCaseKeysToSnakeCase,
	convertToPinyin,
	splitPinyinToArray,
	getCurrentLocale,
	isCNCulture,
	decodeURL,
	subString,
	replaceURLToHttps,
	replaceHttpToFitHttpsInHtml,
	getSessionState,
	getCurrentPartnerId,
	formatToHtmlString,
	convertCamelCaseKeysToUpperSnakeCase,
	getENNumSeqSuffix,
	generateUUID,
	isGuest,
	isLoggedIn,
	isAdmin,
	isOnCall,
	isTeacher,
	isAgent,
	isBookManager,
	isPartner,
	isEditorPartner,
	isManagerPartner,
	isAssistantPartner,
	isNumeric,
	isNullOrEmpty,
	isNullOrUndefined,
	convertToFileServerPath,
	isCharacter,
	randomizeArray,
	getAudioVirtualPathFromSyllable,
	localizeString,
	localizeStringByLanguage,
	translateChinese,
	buildLocalizableString,
	isTwoWordsSame,
	combineTranslation,
	createArrayWithNumbers,
	isChinese,
	isEnglish,
	isChineseWithPunctuation,
    hasChineseChars,
	combineFieldsIntoSentences,
	extractFieldsFromSentences,
    getWords,
    extractFieldsFromContentFieldFormData,
    combineContentField,
    extractFieldsFromContentFieldPayloads,
	isMp3,
	getLocale,
	getCountries,
	getCountryName,
	downloadFile,
	getLastFileName,
	extractFieldsFromParagraphs,
	combineFieldsIntoParagraphs,
	isPunctuation,
	numberWithCommas,
	containSpecialPunctuations,
	mapSentenceSyllablesIntoWords,
	combineFieldsIntoDialog,
	convertToLocalTime,
	convertToLocalString,
	convertToUtcTime,
	formatToUtcTime,
	addDays,
	getTimeZone,
	isNumberWithPunctuations,
	containSentencePunc,
	splitByDigit,
	displayPhone,
	buildFilePathInUnits,
    stripDeletedEmail,
	getLanguageCodes,
	existsSepcialContent,
	getImageWidthHeightWithPath,
	convertToAbsoluteUrl,
	buildWordAudioName,
	getUnicodeOfChineseChar,
	getAvatarStr,
	isStudent,
	createTwoDimensionArray,
	getNumberStr,
	parseSpeechTestResultForSentenceComponent,
	parseSpeechTestResultForWordComponent,
	phrasesInAnswer,
	buildPathSegments,
	isSubjective,
	removeSepcialUnicode,
	getWeekDays,
	getContainerSize,
	clearCache,
	isChineseCharacters,
	formatTimeDuration,
    getRealUnitType,
    convertAnswerToJson,
    parseAnswerJson,
	isNotDefaultAnswerType,
	isImageAnswerType,
	localizeStringNoChinese,
	isRecordAnswerType,
	extractAllValuesIntoArray,
	IsNoNeedCompareChar,
	convertNumToCircleNum,
    getI18nNSWithAllUnitView,
    insertHtmlContentPrefix,
    replaceHtmlVirtualPath,
	permutations,
	permutationsAll,
	hideInitPageLoadingIcon,
	getHtmlInnerTextLength,
    isHtmlContentEmpty,
    trimTailZero,
	combineTranslationForSentences,
	getUserReadingAnswerTotalChars,
    getNewAttribute,
    renderTemplate
};
